import { TeamItemFragment } from '@packages/core/graphql/types/graphql.ts';
import useGetBranchTeam from '@client/page/management/appointment/service/service-product/common-component/logic/get-branch-team.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { getFragmentData } from '@client/graphql/types';
import { TeamFragment } from '@packages/network-graphql/common/team-fragment.ts';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import { useState } from 'react';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { MagnifyingGlassIcon } from '@heroicons/react/16/solid';

interface TeamStatus {
  enabled: boolean;
  team: TeamItemFragment;
}

export default function ServiceProductTeam(props: {
  teamIds: string[];
  onClick: (checked: boolean, id: string) => void;
}) {
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const { data: branchTeamRawData } = useGetBranchTeam(branchId);

  const [nameSearch, setNameSearch] = useState<string>('');

  const branchTeamData = branchTeamRawData?.teams.map((item) => getFragmentData(TeamFragment, item));
  const formatedData: TeamStatus[] =
    branchTeamData?.map((item) => {
      return props.teamIds.includes(item.id) ? { enabled: true, team: item } : { enabled: false, team: item };
    }) ?? [];

  return (
    <Card>
      <CardHeader title="Teams" />
      <CardTableContent>
        <div className="divide-y">
          <div className="flex justify-center items-center">
            <MagnifyingGlassIcon className="w-8 h-8 mx-4 text-gray-400" />
            <input
              type="text"
              value={nameSearch}
              name="nameSearch"
              placeholder={'Type to find name'}
              onChange={(e) => {
                setNameSearch(e.target.value);
              }}
              className="w-full border-none py-4 px-2 placeholder:text-gray-400 order-transparent focus:border-transparent focus:ring-0"
            />
          </div>

          {formatedData
            .filter((item) => {
              return nameSearch == '' ? true : item.team.name.toLowerCase().includes(nameSearch.toLowerCase().trim());
            })
            .map((data) => (
              <div key={data.team.id} className="flex px-5 py-4 gap-6 truncate">
                <CheckBoxInput
                  value={data.enabled}
                  onChange={(_, value) => {
                    props.onClick(value, data.team.id);
                  }}
                />
                {data.team.name}
              </div>
            ))}
        </div>
      </CardTableContent>
    </Card>
  );
}
