import { graphql } from '@client/graphql/types';

export const NailAppointmentFragment = graphql(`
  fragment NailAppointmentItem on NailAppointment {
    id
    branchId
    customerVariantId
    customerVariant {
      id
      name
      phone
      email
      customerId
    }
    confirmationStatus
    startTime
    endTime
    message
    teams {
      ...PickedNailAppointmentTeamItem
    }
    employees {
      ...PickedNailAppointmentEmployeeItem
    }
    products {
      id
      appointmentId
      pickedProduct {
        id
        enabled
        title
        description
        images
        code
        configurations {
          id
          title
          value
          price
        }
      }
    }
  }
`);
