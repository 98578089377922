import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation CreateAppointmentMenuProductDataTeam($teamId: ID!, $serviceProductId: ID!) {
    createAppointmentMenuProductDataTeam(teamId: $teamId, serviceProductId: $serviceProductId) {
      id
      teamId
      menuProductDataId
    }
  }
`);

export default function useAssignTeam() {
  return useMutation(MUTATION, {});
}
