import { graphql } from '../graphql/types';
export const ServiceFragment = graphql(`
  fragment ServiceItem on NailAppointmentMenuProduct {
    id
    code
    title
    description
    images
    enabled
    ingredients
    labels
    allergics
    priceMode
    section {
      ...MenuSectionItem
    }
    configurations {
      ...MenuProductConfigurationItem
    }

    appointmentMenuProductData {
      duration
    }

    menuId
    sectionId
  }
`);
