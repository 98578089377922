import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import { AppointmentPaginationFooter } from '@client/page/management/appointment/list/component/appointment-paginition-footer.tsx';
import { useSearchParams } from 'react-router-dom';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback } from 'react';
import AppointmentList from '@client/page/management/appointment/list/appointment-list.tsx';
import { AppointmentSimpleFilter } from '@client/page/management/appointment/list/component/appointment-simple-filter.tsx';

export default function AppointmentSinglePage() {
  const [params, setParams] = useSearchParams();
  const { settings } = useFocusedCompany();

  const startTime = dayjs(params.get('startTime') ?? dayjs())
    .tz(settings.timezone)
    .startOf('day');
  const endTime = dayjs(params.get('endTime') ?? dayjs())
    .tz(settings.timezone)
    .endOf('day');

  // Function
  const setTime = useCallback(
    (start: Dayjs, end: Dayjs) => {
      params.set('startTime', start.toISOString());
      params.set('endTime', end.toISOString());
      setParams(params);
    },
    [params, setParams]
  );
  return (
    <ContentLayout>
      <PrimaryContent>
        <AppointmentList
          startTime={startTime}
          endTime={endTime}
          header={
            <AppointmentSimpleFilter
              startTime={startTime}
              endTime={endTime}
              onChange={(data) => {
                setTime(data[0], data[1]);
              }}
            />
          }
          footer={
            <AppointmentPaginationFooter
              startTime={startTime}
              endTime={endTime}
              onChange={(data) => {
                setTime(data[0], data[1]);
              }}
            />
          }
        />
      </PrimaryContent>
    </ContentLayout>
  );
}
