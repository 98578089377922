import { Navigate, RouteObject } from 'react-router-dom';
import ServiceList from '@client/page/management/appointment/service/service-list/service-list.tsx';
import ServiceCreate from '@client/page/management/appointment/service/service-create/service-create.tsx';
import ServiceProductCreate from '@client/page/management/appointment/service/service-product/service-product-create/service-product-create.tsx';
import ServiceMenuDetail from '@client/page/management/appointment/service/service-detail/service-detail.tsx';
import ServiceSectionCreate from '@client/page/management/appointment/service/service-section/service-section-create.tsx';
import ServiceSectionEdit from '@client/page/management/appointment/service/service-section/service-section-edit.tsx';
import ServiceProductEdit from '@client/page/management/appointment/service/service-product/service-product-edit/service-product-edit.tsx';
import AppointmentSinglePage from '@client/page/management/appointment/list/appointment-single-page.tsx';
import AppointmentDetail from '@client/page/management/appointment/detail/appointment-detail.tsx';
import AppointmentBranchConfiguration from '@client/page/management/appointment/configuration/appointment-branch-configuration.tsx';

export const AppointmentRouter: RouteObject = {
  path: 'appointment',
  children: [
    {
      path: 'configuration',
      children: [
        {
          path: 'general',
          handle: {
            crumb: () => 'General',
          },
          element: <AppointmentBranchConfiguration />,
        },
        {
          path: 'opening-time',
          handle: {
            crumb: () => 'Time',
          },
          element: <AppointmentBranchConfiguration />,
        },
      ],
    },
    {
      path: 'list',
      children: [
        {
          path: '',
          handle: {
            crumb: () => 'All appointment',
          },
          element: <AppointmentSinglePage />,
        },
        {
          path: ':appointmentId',
          handle: {
            crumb: () => 'breadcumb.appointment.detail',
          },
          element: <AppointmentDetail />,
        },
      ],
    },
    {
      path: 'service',
      handle: {
        crumb: () => 'All menu services',
      },
      children: [
        {
          path: '',
          element: <ServiceList />,
        },
        {
          path: 'create',
          handle: {
            crumb: () => 'Create',
          },
          element: <ServiceCreate />,
        },
        {
          path: ':serviceId',
          handle: {
            crumb: () => 'Detail',
          },
          children: [
            {
              path: '',
              element: <ServiceMenuDetail />,
            },
            {
              path: 'products',
              children: [
                {
                  path: 'create',
                  handle: {
                    crumb: () => 'Add service',
                  },
                  element: <ServiceProductCreate />,
                },
                {
                  path: ':productId',
                  handle: {
                    crumb: () => 'Update service',
                  },
                  element: <ServiceProductEdit />,
                },
              ],
            },
            {
              path: 'sections',
              handle: {
                crumb: () => 'breadcumb.service.section',
              },
              children: [
                {
                  index: true,
                  element: <Navigate replace to=".." />,
                },
                {
                  path: 'create',
                  handle: {
                    crumb: () => 'breadcumb.service.create-section',
                  },
                  element: <ServiceSectionCreate />,
                },
                {
                  path: ':menuSectionId/edit',
                  handle: {
                    crumb: () => 'breadcumb.service.edit-category',
                  },
                  element: <ServiceSectionEdit />,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
