import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation DeleteAppointmentMenuProductDataTeam($id: ID!) {
    deleteAppointmentMenuProductDataTeam(id: $id) {
      id
      teamId
      menuProductDataId
    }
  }
`);

export default function useDeleteTeam() {
  return useMutation(MUTATION, {});
}
