import {
  NailAppointmentConfirmationStatus,
  NailAppointmentItemFragment,
} from '@client/graphql/types/graphql.ts';
import { useTranslate } from "@tolgee/react";

interface StatItem {
  name: string;
  value: string | number;
  unit?: string;
}

const NailAppointmentListStats = (props: {
  loading?: boolean;
  items: NailAppointmentItemFragment[];
}) => {
  const { items } = props;

  const stats: StatItem[] = [
    {
      name: 'Bookings',
      value: items.filter(
        (item) =>
          item.confirmationStatus ===
          NailAppointmentConfirmationStatus.Confirmed,
      ).length,
    },
    {
      name: 'Guests',
      value: items
        .filter(
          (item) =>
            item.confirmationStatus ===
            NailAppointmentConfirmationStatus.Confirmed,
        )
        .length,
    },
    {
      name: 'Waiting',
      value: items.filter(
        (item) =>
          item.confirmationStatus === NailAppointmentConfirmationStatus.Waiting,
      ).length,
    },

    {
      name: 'Cancelled',
      value: items.filter(
        (item) =>
          item.confirmationStatus ===
          NailAppointmentConfirmationStatus.Cancelled,
      ).length,
    },
  ];
  const { t } = useTranslate();

  return (
    <div className="px-6 py-2 flex flex-col space-y-0">
      <div className="text-lg text-gray-700">
        <span className="">
          {stats[0].value} {t('reservation.schedule.stats.booking')} / {stats[1].value} {t('reservation.schedule.stats.guests')}
        </span>
      </div>
      <div>
        <span className="text-sm text-gray-500">
          {stats[2].value} {t('reservation.schedule.stats.waiting')} / {stats[3].value} {t('reservation.schedule.stats.cancellation')}
        </span>
      </div>
    </div>
  );
};

export default NailAppointmentListStats;
