import {
  PickedNailAppointmentEmployeeItemFragment,
  PickedNailAppointmentTeamItemFragment,
} from '@client/graphql/types/graphql.ts';
import CheckBoxInput from '@packages/ui/form/input/check-box-input.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';

export default function PickedNailApointmentTeam(props: {
  team: PickedNailAppointmentTeamItemFragment | undefined | null;
  employees: PickedNailAppointmentEmployeeItemFragment[] | undefined | null;
  switchTeam: () => void;
  assignEmployee: (userId: string) => void;
}) {
  const members = props.team?.team.teamMember.map((member) => {
    return props.employees?.find((employee) => employee.user.userId == member.user.id)
      ? { enabled: true, member: member.user }
      : { enabled: false, member: member.user };
  });

  return (
    <Card>
      <CardHeader title={props.team?.teamName ?? ''}>
        <SecondaryButton
          onClick={() => {
            props.switchTeam();
          }}
        >
          Switch team
        </SecondaryButton>
      </CardHeader>

      <CardTableContent>
        <div className="divide-y">
          {members?.map((member) => (
            <div key={member.member.id} className="flex px-5 py-4 gap-6 truncate">
              <CheckBoxInput
                value={member.enabled}
                onChange={() => {
                  props.assignEmployee(member.member.id);
                }}
              />
              {`${member.member.firstName} ${member.member.lastName}`}
            </div>
          ))}
        </div>
      </CardTableContent>
    </Card>
  );
}
