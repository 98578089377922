import dayjs from 'dayjs';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from "@tolgee/react";

export function AppointmentPaginationFooter(props: {
  startTime: dayjs.Dayjs;
  endTime: dayjs.Dayjs;
  onChange: (dates: [dayjs.Dayjs, dayjs.Dayjs]) => void;
}) {
  const previousDayHandler = () => {
    props.onChange([
      props.startTime.subtract(1, 'day'),
      props.endTime.subtract(1, 'day'),
    ]);
  };

  const nextDayHandler = () => {
    props.onChange([
      props.startTime.add(1, 'day'),
      props.endTime.add(1, 'day'),
    ]);
  };

  const { t } = useTranslate();

  return (
    <>
      <SecondaryButton onClick={previousDayHandler}>
        {t('common.button.previous-day')}
      </SecondaryButton>
      <div className="flex-grow" />
      <SecondaryButton onClick={nextDayHandler}>
        {t('common.button.next-day')}
      </SecondaryButton>
    </>
  );
}
