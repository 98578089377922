import { ContentLayout, FullPrimaryContent } from '@packages/ui/content-layout.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import Card from '@packages/ui/card/card.tsx';
import {
  MenuProductConfiguration,
  MenuProductConfigurationValue,
  MenuProductLabel,
  MenuProductPriceMode,
  ProductConfigurationType,
} from '@client/graphql/types/graphql.ts';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import { Control, Controller, DeepRequired, FieldErrorsImpl, GlobalError, UseFieldArrayUpdate } from 'react-hook-form';
import classNames from 'classnames';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import TextInput from '@packages/ui/form/input/text-input.tsx';
import { currencySymbol } from '@packages/core/utils/currency-formatter.ts';
import { Fragment } from 'react';
import { useTranslate } from '@tolgee/react';

interface MenuProductForm {
  title: string;
  description: string;
  images: string[];
  ingredients: string[];
  sectionId: string | null;
  configurations: MenuProductConfiguration[];
  code: string;
  labels: MenuProductLabel[];
  allergics: string;
  priceMode: MenuProductPriceMode;
}

export default function SimpleProductPriceModeConfiguration(props: {
  fields: MenuProductConfiguration[];
  update: UseFieldArrayUpdate<MenuProductForm, 'configurations'>;
  control: Control<MenuProductForm> | undefined;
  errors: Partial<FieldErrorsImpl<DeepRequired<MenuProductForm>>> & {
    root?: Record<string, GlobalError> & GlobalError;
  };
  isDisplayed: boolean;
}) {
  const { control, fields, update, errors, isDisplayed } = props;
  const currency = useCompanyCurrency();
  const { t } = useTranslate();

  if (!isDisplayed) {
    return null;
  }

  const addConfigurationValue = (configIndex: number, newConfigValue: MenuProductConfigurationValue) => {
    update(configIndex, { ...fields[configIndex], values: [...fields[configIndex].values, newConfigValue] });
  };

  const removeConfigurationValue = (configIndex: number, configValueId: string) => {
    update(configIndex, {
      ...fields[configIndex],
      values: fields[configIndex].values.filter((item) => item.id !== configValueId),
    });
  };

  const handlePriceInput = (price: number) => {
    if (isNaN(price)) {
      return 0;
    }
    return String(price);
  };

  return (
    <div className="space-y-4">
      {fields.map((config, index) => {
        const title =
          config.type == ProductConfigurationType.Base
            ? t('menu.menu-detail.product.simple-mode.product-variation.header', 'Product variation')
            : t('menu.menu-detail.product.simple-mode.product-extra.header', 'Product extra');
        const buttonText =
          config.type == ProductConfigurationType.Base
            ? t('menu.menu-detail.product.simple-mode.product-variation.add-button', 'Add variation')
            : t('menu.menu-detail.product.simple-mode.product-extra.add-button', 'Add extra');
        return (
          <ContentLayout key={config.id}>
            <FullPrimaryContent>
              <Card>
                <CardHeader title={title}>
                  <SecondaryButton
                    onClick={() => {
                      const generateId = uuidv4();
                      addConfigurationValue(index, {
                        id: generateId,
                        name: '',
                        price: 0,
                      });
                    }}
                  >
                    {buttonText}
                  </SecondaryButton>
                </CardHeader>

                <CardContent>
                  <table className="w-full max-w-xl">
                    <thead className="">
                      <tr className="">
                        <th className="text-start w-6/8">
                          {t('menu.menu-detail.product.simple-mode.common.name.title', 'Name')}
                        </th>
                        <th className="text-align w-2/8">
                          {t('menu.menu-detail.product.simple-mode.common.price.title', 'Price')}
                        </th>
                        <th className="w-1/8"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {config.values.map((configValue, valueIndex) => {
                        const rule =
                          config.type == ProductConfigurationType.Base && valueIndex === 0
                            ? {}
                            : {
                                required: t(
                                  'menu.menu-detail.product.simple-mode.common.name.validate.required',
                                  'Value name is required'
                                ),
                                minLength: {
                                  value: 3,
                                  message: t(
                                    'menu.menu-detail.product.simple-mode.common.name.validate.min-length',
                                    'Min length is 3'
                                  ),
                                },
                                maxLength: {
                                  value: 50,
                                  message: t(
                                    'menu.menu-detail.product.simple-mode.common.name.validate.max-length',
                                    'Max length is 50'
                                  ),
                                },
                              };

                        return (
                          <Fragment key={configValue.id}>
                            <tr>
                              <td className="py-1.5 px-1">
                                <Controller
                                  rules={rule}
                                  control={control}
                                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                  name={`configurations.${index}.values.${valueIndex}.name`}
                                  render={({ field: { onChange, value } }) => (
                                    <input
                                      type="text"
                                      name=""
                                      placeholder={
                                        config.type == ProductConfigurationType.Base && valueIndex == 0
                                          ? 'Optional'
                                          : 'Required'
                                      }
                                      value={value}
                                      autoComplete={'off'}
                                      onChange={onChange}
                                      className={classNames(
                                        'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ',
                                        'placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                                        {
                                          'ring-red-300 placeholder:text-red-300':
                                            errors.configurations?.[index]?.values?.[valueIndex]?.name != null,
                                        }
                                      )}
                                    />
                                  )}
                                />
                              </td>
                              <td className="py-1.5 px-1 max-w-16">
                                <Controller
                                  control={control}
                                  rules={{
                                    required: t(
                                      'menu.menu-detail.product.simple-mode.common.price.validate.required',
                                      'Value is required'
                                    ),
                                  }}
                                  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                                  name={`configurations.${index}.values.${valueIndex}.price`}
                                  render={({ field: { onChange, value } }) => (
                                    <TextInput
                                      type="number"
                                      name=""
                                      placeholder={''}
                                      value={handlePriceInput(value)}
                                      autoComplete={'off'}
                                      onChange={onChange}
                                      error={errors.configurations?.[index]?.values?.[valueIndex]?.price && ''}
                                      suffix={<span className="ml-2 text-gray-500">{currencySymbol(currency)}</span>}
                                      label=""
                                    />
                                  )}
                                />
                              </td>
                              <td>
                                {config.type == ProductConfigurationType.Base && valueIndex == 0 ? (
                                  <div className="w-8 h-8"></div>
                                ) : (
                                  <XMarkIcon
                                    onClick={() => {
                                      removeConfigurationValue(index, configValue.id);
                                    }}
                                    className="w-6 h-6 text-gray-400"
                                  />
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {errors.configurations?.[index]?.values?.[valueIndex]?.name && (
                                  <p className="ml-1 text-red-500 text-sm">
                                    {errors.configurations[index].values[valueIndex].name.message}
                                  </p>
                                )}
                              </td>
                              <td>
                                {errors.configurations?.[index]?.values?.[valueIndex]?.price && (
                                  <>
                                    {' '}
                                    <p className="ml-1 absolute text-red-500 text-sm max-w-28">
                                      {errors.configurations[index].values[valueIndex].price.message}
                                    </p>
                                    <p className="ml-1 mb-5 max-w-28"></p>
                                  </>
                                )}
                              </td>
                            </tr>
                          </Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </CardContent>
              </Card>
            </FullPrimaryContent>
          </ContentLayout>
        );
      })}
    </div>
  );
}
