import { useParams } from 'react-router-dom';
import useNailAppointment from '@client/page/management/appointment/detail/logic/use-nail-appointment.ts';
import { getFragmentData } from '@client/graphql/types';
import { NailAppointmentFragment } from '@client/graphql/nail-appointment-fragment.ts';
import { ContentLayout, FullPrimaryContent, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import moment from 'moment';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { AppointmentConfirmationStatusSelection } from '@client/page/management/appointment/detail/component/appointment-confirmation-status-selection.tsx';
import CustomerHistory from '@client/page/management/appointment/detail/component/customer-history.tsx';
import {
  PickedNailAppointmentEmployeeFragment,
  PickedNailAppointmentTeamFragment,
} from '@packages/network-graphql/common/appointment-picked-team.ts';
import PickedNailApointmentTeam from '@client/page/management/appointment/detail/component/picked-nail-appointment-team.tsx';
import useSwitchTeamForAppointment from '@client/page/management/appointment/detail/logic/use-switch-team.ts';
import useAssignEmployeeForAppointment from '@client/page/management/appointment/detail/logic/use-assign-employee.ts';
import { useModalManagerContext } from '@packages/ui/modal/modal-manager-context.ts';
import useGetBranchTeam from '@client/page/management/appointment/service/service-product/common-component/logic/get-branch-team.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { TeamFragment } from '@packages/network-graphql/common/team-fragment.ts';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';

export default function AppointmentDetail() {
  const appointmentId = useParams().appointmentId ?? '';
  const company = useFocusedCompany();
  const branchId = useFocusedBranchContext().branch?.id ?? '';
  const dialogManager = useModalManagerContext();
  const { showAlert } = useNotificationCenter();

  const { data, refetch } = useNailAppointment(appointmentId);
  const { data: teamListData } = useGetBranchTeam(branchId);
  const [switchTeam] = useSwitchTeamForAppointment();
  const [assignEmployee] = useAssignEmployeeForAppointment();

  const appointmentData = getFragmentData(NailAppointmentFragment, data?.nailAppointment);
  const teamData = getFragmentData(PickedNailAppointmentTeamFragment, appointmentData?.teams);
  const employee = getFragmentData(PickedNailAppointmentEmployeeFragment, appointmentData?.employees);

  if (!appointmentData) {
    return <>Appoiment not found</>;
  }

  const refetchData = () => {
    void refetch();
  };

  const handleSwitchTeam = () => {
    dialogManager.showDialog((onClose) => {
      const onSubmit = (teamId: string) => {
        switchTeam({
          variables: {
            appointmentId: appointmentId,
            teamId: teamId,
          },
        })
          .then(() => {
            onClose();
            showAlert({
              status: 'success',
              title: 'Update success',
              message: 'Switch team successful',
            });
            void refetch();
          })
          .catch((e) => void e);
      };

      return (
        <Card>
          <CardHeader title="Switch team" />
          <CardContent>
            <div className="text-lg font-medium mb-2">Team list</div>
            <div className="text-sm italic text-gray-400 mb-4">Click to switch, this action can be undone</div>
            <div className="flex flex-col gap-1.5">
              {teamListData?.teams.map((teamFragment) => {
                const team = getFragmentData(TeamFragment, teamFragment);
                return (
                  <div
                    className="border rounded-md py-1.5 px-2 bg-gray-50 hover:bg-white"
                    onClick={() => {
                      onSubmit(team.id);
                    }}
                    key={team.id}
                  >
                    {team.name}
                  </div>
                );
              })}
            </div>
          </CardContent>
          <CardFooter>
            <SecondaryButton>Cancel</SecondaryButton>
          </CardFooter>
        </Card>
      );
    });
  };

  const handleAssignEmployee = (userId: string) => {
    assignEmployee({
      variables: {
        appointmentId: appointmentId,
        userId: userId,
      },
    })
      .then(() => {
        void refetch();
      })
      .catch((e) => void e);
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col xl:flex-row gap-4">
        <ContentLayout className="xl:basis-2/3">
          <FullPrimaryContent>
            <Card>
              <CardHeader title="Appointment" withBackButton={true} backTarget={-1} />
              <CardContent>
                <div className="space-y-8">
                  <AppointmentConfirmationStatusSelection
                    appointmentId={appointmentId}
                    status={appointmentData.confirmationStatus}
                    refetchData={refetchData}
                  />
                  <AppointmentItem label={'Service'} value={appointmentData.products[0].pickedProduct.title} />
                  <AppointmentItem label={'Name'} value={appointmentData.customerVariant.name} />
                  {appointmentData.message != '' && (
                    <AppointmentItem label={'Message'} value={appointmentData.message ?? ''} />
                  )}
                  <AppointmentTimeItem
                    startTime={appointmentData.startTime ?? ''}
                    timezone={company.settings.timezone}
                  />
                </div>
              </CardContent>
            </Card>
          </FullPrimaryContent>
        </ContentLayout>

        <div>
          {teamData?.map((team) => (
            <div className="xl:basic-1/3 2xl:min-w-96" key={team.id}>
              <PickedNailApointmentTeam
                team={team}
                employees={employee}
                switchTeam={handleSwitchTeam}
                assignEmployee={handleAssignEmployee}
              />
            </div>
          ))}
        </div>
      </div>

      <ContentLayout>
        <PrimaryContent>
          <div className="space-y-4">
            <CustomerHistory
              appointmentId={appointmentData.id}
              customerId={appointmentData.customerVariant.customerId ?? ''}
              currentAppointmentTime={appointmentData.startTime ?? ''}
            />
            <Card>
              <CardHeader title="Contact Detail" />
              <CardContent>
                <div className="space-y-8">
                  <AppointmentItem label={'Email'} value={appointmentData.customerVariant.email} />
                  <AppointmentItem label={'Phone'} value={appointmentData.customerVariant.phone} />
                </div>
              </CardContent>
            </Card>
          </div>
        </PrimaryContent>
      </ContentLayout>
    </div>
  );
}

function AppointmentItem(props: { label: string; value: string; className?: string }) {
  return (
    <div className={props.className}>
      <p className="mb-2 text-sm">{props.label}</p>
      <p className="text-md ml-0.5 font-light">{props.value}</p>
    </div>
  );
}

function AppointmentTimeItem(props: { startTime: string; timezone: string }) {
  const { startTime, timezone } = props;
  return (
    <div className="flex max-w-md gap-4">
      <div>
        <p className="mb-2 text-sm">Booking date</p>
        <p className="py-1.5 px-3 rounded-md border border-gray-300">
          {moment(startTime).tz(timezone).format('ddd DD MMMM')}
        </p>
      </div>

      <div>
        <p className="mb-2 text-sm">Time</p>
        <p className="py-1.5 px-3 rounded-md border border-gray-300">
          {moment(startTime).tz(timezone).format('HH:mm')}
        </p>
      </div>
    </div>
  );
}
