import { graphql } from '@client/graphql/types';

export const NailAppointmentConfigurationFragment = graphql(`
  fragment NailAppointmentConfigurationItem on AppointmentBranchConfiguration {
    branchId
    menuId
    enable
    hourBlocking
    autoConfirm

    appointmentLeadMinutes
    appointmentTraitMinutes

    reminderTime
    menu {
      title
    }
  }
`);
