/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment BranchItem on Branch {\n    id\n    name\n    website\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n  }\n": types.BranchItemFragmentDoc,
    "\n  fragment InvoiceItem on Invoice {\n    id\n    total\n    place\n    shortID\n    invoiceLines {\n      ...InvoiceLineItem\n    }\n    \n    invoiceSecondaryLines {\n      ...InvoiceSecondaryLineItem\n    }\n    createdAt\n  }\n": types.InvoiceItemFragmentDoc,
    "\n  fragment InvoiceLineItem on InvoiceLine {\n    id\n    title\n    subtitle\n    quantity\n    price\n    total\n  }\n": types.InvoiceLineItemFragmentDoc,
    "\n  fragment InvoiceSecondaryLineItem on InvoiceSecondaryLines {\n    id\n    title\n    price\n    description\n    invoiceCategory\n  }\n": types.InvoiceSecondaryLineItemFragmentDoc,
    "\n  fragment NailAppointmentConfigurationItem on AppointmentBranchConfiguration {\n    branchId\n    menuId\n    enable\n    hourBlocking\n    autoConfirm\n\n    appointmentLeadMinutes\n    appointmentTraitMinutes\n\n    reminderTime\n    menu {\n      title\n    }\n  }\n": types.NailAppointmentConfigurationItemFragmentDoc,
    "\n  fragment NailAppointmentItem on NailAppointment {\n    id\n    branchId\n    customerVariantId\n    customerVariant {\n      id\n      name\n      phone\n      email\n      customerId\n    }\n    confirmationStatus\n    startTime\n    endTime\n    message\n    teams {\n      ...PickedNailAppointmentTeamItem\n    }\n    employees {\n      ...PickedNailAppointmentEmployeeItem\n    }\n    products {\n      id\n      appointmentId\n      pickedProduct {\n        id\n        enabled\n        title\n        description\n        images\n        code\n        configurations {\n          id\n          title\n          value\n          price\n        }\n      }\n    }\n  }\n": types.NailAppointmentItemFragmentDoc,
    "\n  fragment OnlineOrderBranchSettingItem on OnlineOrderBranchConfiguration {\n    branchID\n    menuID\n\n    pickupEnable\n    autoPickupConfirm  \n\n    defaultPreparingTime\n\n    pickupDiscountAmount\n    pickupDiscountType\n\n    categoryStyle\n\n    deliveryEnable\n    autoDeliveryConfirm  \n    deliveryRadiusLimit\n    minimumOrderAmountForDelivery\n    deliveryTableData {\n      id\n      deliveryDistance\n      deliveryTime\n      deliveryFee\n    }\n  }\n": types.OnlineOrderBranchSettingItemFragmentDoc,
    "\n  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {\n    id\n    state\n    timestamp\n  }\n": types.OnlineOrderStateEventItemFragmentDoc,
    "\n  fragment OnlineOrderProductsItem on OnlineOrderProduct {\n    id\n    title\n    quantity\n\n    menuProduct {\n      id\n      images\n    }\n\n    configurations {\n      id\n      title\n      value\n      price\n\n      menuProductConfigurationId\n      menuProductConfigurationValueId\n    }\n  }\n": types.OnlineOrderProductsItemFragmentDoc,
    "\n  fragment OnlineOrderItem on OnlineOrder {\n    id\n    shortID\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    distanceToRestaurant\n    longitude\n    latitude\n\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    pickupDiscount\n    totalPrice\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    onlineOrderProducts {\n      ...OnlineOrderProductsItem\n    }\n\n    userCreatedAt\n    createdAt\n  }\n": types.OnlineOrderItemFragmentDoc,
    "\n  fragment OnlineOrderListItem on OnlineOrder {\n    id\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    distanceToRestaurant\n    longitude\n    latitude\n\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    totalPrice\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    userCreatedAt\n    createdAt\n\n    _count {\n      onlineOrderProducts\n    }\n  }\n": types.OnlineOrderListItemFragmentDoc,
    "\n  fragment OpeningTimeItem on OpeningTime {\n    id\n    branchId\n    dayOfWeek\n    openTime\n    closeTime\n    note\n    isActive\n  }\n": types.OpeningTimeItemFragmentDoc,
    "\n  fragment TableItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n  }\n": types.TableItemFragmentDoc,
    "\n  fragment TableWithGroupItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n    tableGroup {\n      id\n      name\n      arrangementCode\n    }\n  }\n": types.TableWithGroupItemFragmentDoc,
    "\n  fragment TableGroupItem on TableGroup {\n    id\n    name\n    arrangementCode\n  }\n": types.TableGroupItemFragmentDoc,
    "\n  fragment TableReservationItem on TableReservation {\n    id\n    platformType\n    tableId\n    guestName\n    status\n    email\n    message\n    startTime\n    phone\n    seats\n    confirmationStatus\n    timezone\n    flexTime\n  }\n": types.TableReservationItemFragmentDoc,
    "\n  fragment UserCompanyRoleItem on UserCompanyRole {\n    id\n    user {\n      id\n      firstName\n      lastName\n      username\n      role\n      email\n      status\n    }\n  }\n": types.UserCompanyRoleItemFragmentDoc,
    "\n  mutation UpdatePushToken($token: String!) {\n    pushNotificationRegisterToken(token: $token)\n  }\n": types.UpdatePushTokenDocument,
    "\n  query PushTokens {\n    pushNotificationTokens\n  }\n": types.PushTokensDocument,
    "\n  mutation CreateInternalAPIKey($input: CreateInternalAPIKeyInput!) {\n    generateInternalAPIKey(input: $input)\n  }\n": types.CreateInternalApiKeyDocument,
    "\n  query ApiKeyListPage {\n    internalAPIKeys {\n      id\n      key\n      description\n      createdAt\n      updatedAt\n    }\n  }\n": types.ApiKeyListPageDocument,
    "\n  mutation CreateCompany($input: CreateCompanyInput!) {\n    createCompany(input: $input) {\n      id\n      name\n    }\n  }\n": types.CreateCompanyDocument,
    "\n  query AppointmentBranchConfiguration($branchId: ID!) {\n    appointmentBranchConfiguration(branchId: $branchId) {\n      ...NailAppointmentConfigurationItem\n    }\n  }\n": types.AppointmentBranchConfigurationDocument,
    "\n  mutation InitiateAppointmentBranchConfiguration($branchId: ID!) {\n    initiateAppointmentBranchConfiguration(branchId: $branchId) {\n      branchId\n      enable\n    }\n  }\n": types.InitiateAppointmentBranchConfigurationDocument,
    "\n  mutation UpdateAppointmentBranchConfiguration($branchId: ID!, $input: AppointmentConfigurationUpdateInput!) {\n    updateAppointmentBranchConfiguration(branchId: $branchId, input: $input) {\n      branchId\n      enable\n    }\n  }\n": types.UpdateAppointmentBranchConfigurationDocument,
    "\n  mutation assignEmployeeForAppointment($appointmentId: ID!, $userId: ID!) {\n    assignEmployeeForAppointment(appointmentId: $appointmentId, userId: $userId) {\n      id\n    }\n  }\n": types.AssignEmployeeForAppointmentDocument,
    "\n    query CustomerHistory($id: ID!) {\n        customerAppointmentHistory(id: $id) {\n            appointmentId\n            detail\n            time\n        }\n    }\n": types.CustomerHistoryDocument,
    "\n  query NailAppointment($id: ID!) {\n    nailAppointment(id: $id) {\n      ...NailAppointmentItem\n    }\n  }\n": types.NailAppointmentDocument,
    "\n  mutation switchTeamForAppointment($appointmentId: ID!, $teamId: ID!) {\n    switchTeamForAppointment(appointmentId: $appointmentId, teamId: $teamId) {\n      id\n      teamName\n      appointmentId\n      teamId\n    }\n  }\n": types.SwitchTeamForAppointmentDocument,
    "\n  mutation updateAppointmentConfirmationStatus($id: ID!, $status: NailAppointmentConfirmationStatus!) {\n    updateAppointmentConfirmationStatus(id: $id, status: $status) {\n      id\n    }\n  }\n": types.UpdateAppointmentConfirmationStatusDocument,
    "\n  subscription AppointmentUpdateSubscribe($branchID: ID!) {\n    appointmentUpdateSubscribe(branchID: $branchID) {\n      event\n      appointment {\n        ...NailAppointmentItem\n      }\n    }\n  }\n": types.AppointmentUpdateSubscribeDocument,
    "\n    query NailAppointments($branchId: ID!, $filter: NailAppointmentFilter!) {\n        nailAppointments(branchId: $branchId, filter: $filter) {\n            ...NailAppointmentItem\n        }\n    }\n": types.NailAppointmentsDocument,
    "\n  query GetServiceDetail($id: ID!) {\n    serviceMenu(id: $id) {\n      id\n      companyId\n      title\n      menuSource\n      sections {\n        ...MenuSectionItem\n      }\n      menuProducts {\n        ...ServiceItem\n      }\n    }\n  }\n": types.GetServiceDetailDocument,
    "\n  query Teams($branchId: ID!) {\n    teams(branchId: $branchId) {\n      ...TeamItem\n    }\n  }\n": types.TeamsDocument,
    "\n  query Team($id: ID!) {\n    team(id: $id) {\n      ...TeamItem\n    }\n  }\n": types.TeamDocument,
    "\n  mutation CreateAppointmentMenuProduct(\n    $menuID: ID!\n    $input: CreateAppointmentMenuProductInput!\n    $teamIds: [String!]!\n  ) {\n    createAppointmentMenuProduct(menuID: $menuID, input: $input, teamIds: $teamIds) {\n      id\n    }\n  }\n": types.CreateAppointmentMenuProductDocument,
    "\n  mutation CreateAppointmentMenuProductDataTeam($teamId: ID!, $serviceProductId: ID!) {\n    createAppointmentMenuProductDataTeam(teamId: $teamId, serviceProductId: $serviceProductId) {\n      id\n      teamId\n      menuProductDataId\n    }\n  }\n": types.CreateAppointmentMenuProductDataTeamDocument,
    "\n  mutation DeleteAppointmentMenuProductDataTeam($id: ID!) {\n    deleteAppointmentMenuProductDataTeam(id: $id) {\n      id\n      teamId\n      menuProductDataId\n    }\n  }\n": types.DeleteAppointmentMenuProductDataTeamDocument,
    "\n  query appointmentMenuProduct($id: ID!) {\n    appointmentMenuProduct(id: $id) {\n      id\n      code\n      title\n      priceMode\n      description\n      images\n      ingredients\n      labels\n      priceMode\n      allergics\n      section {\n        id\n        name\n        description\n        code\n      }\n      configurations {\n        id\n        title\n        type\n        values {\n          id\n          name\n          price\n        }\n      }\n\n      appointmentMenuProductData {\n        duration\n        nailAppointmentMenuProductTeams {\n          id\n          teamId\n          menuProductDataId\n        }\n      }\n\n      menuId\n      sectionId\n    }\n  }\n": types.AppointmentMenuProductDocument,
    "\n    mutation UpdateAppointmentMenuProduct($id: ID!, $input: UpdateAppointmentMenuProductInput!) {\n        updateAppointmentMenuProduct(id: $id, input: $input) {\n            id\n        }\n    }\n": types.UpdateAppointmentMenuProductDocument,
    "\n  mutation CreateBanner(\n    $companyID: ID!\n    $input: CreateBannerData!\n    $timeRuleInput: CreateBannerTimeRule!\n    $contentInput: CreateBannerContent!\n  ) {\n    createBanner(\n      companyID: $companyID\n      input: $input\n      timeRuleInput: $timeRuleInput\n      contentInput: $contentInput\n    ) {\n      title\n      viewCount\n      clickCount\n      enabled\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          backgroundColor\n          foregroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n": types.CreateBannerDocument,
    "\n  query Banners(\n    $companyID: String!\n    $offset: Int!\n    $limit: Int!\n    $filter: BannerStatusFilter\n  ) {\n    banners(\n      companyId: $companyID\n      offset: $offset\n      limit: $limit\n      filter: $filter\n    ) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n": types.BannersDocument,
    "\n  mutation DeleteBanner(\n    $bannerId: String!\n  ) {\n    deleteBanner(\n      bannerId: $bannerId\n    ) {\n      bannerId\n      title\n    }\n  }\n": types.DeleteBannerDocument,
    "\n  mutation UpdateBanner(\n    $bannerId: ID!\n    $input: UpdateBannerData!\n    $timeRuleInput: UpdateBannerTimeRule!\n    $contentInput: UpdateBannerContent!\n  ) {\n    updateBanner(\n      bannerId: $bannerId\n      input: $input\n      timeRuleInput: $timeRuleInput\n      contentInput: $contentInput\n    ) {\n      title\n      viewCount\n      clickCount\n      enabled\n      content {\n        __typename\n        ... on TopContent {\n          text\n        }\n        ... on OverlayContent {\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n": types.UpdateBannerDocument,
    "\n  query Banner($id: ID!) {\n    banner(id: $id) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          backgroundColor\n          foregroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n": types.BannerDocument,
    "\n  mutation CreateBranch($companyId: ID!, $input: CreateBranch!) {\n    createBranch(companyId: $companyId, input: $input) {\n      id\n    }\n  }\n": types.CreateBranchDocument,
    "\n  mutation deleteBranch($id: ID!) {\n    deleteBranch(id: $id) {\n      id\n    }\n  }\n": types.DeleteBranchDocument,
    "\n  mutation FetchBranchCoordinate($branchID: ID!) {\n    automaticFetchBranchCoordinate(branchID: $branchID)\n  }\n": types.FetchBranchCoordinateDocument,
    "\n    query Branch($id: ID!) {\n        branch(id: $id) {\n            name\n            website\n            streetAddress\n            addressLocality\n            addressRegion\n            postalCode\n            addressCountry\n\n            latitude\n            longitude\n            contactPhone\n        }\n    }\n": types.BranchDocument,
    "\n  mutation updateBranch($id: ID!, $input: UpdateBranch!) {\n    updateBranch(id: $id, input: $input) {\n      name\n      streetAddress\n      addressLocality\n      addressRegion\n      postalCode\n      addressCountry\n    }\n  }\n": types.UpdateBranchDocument,
    "\n  query CompanyBranches($companyId: ID!) {\n    branches(companyId: $companyId) {\n      ...BranchItem\n    }\n  }\n": types.CompanyBranchesDocument,
    "\n    query Company($id: ID!) {\n        company(id: $id) {\n            id\n            branch {\n                id\n            }\n            settings {\n                id\n                currency\n            }\n            hubPage {\n                companyId\n                subDomain\n                description\n                banner\n            }\n        }\n    }\n": types.CompanyDocument,
    "\n  mutation updateCompany($companyID: ID!, $input: UpdateCompanyInput!) {\n    updateCompany(companyID: $companyID, input: $input) {\n      id\n      logo\n      name\n    }\n  }\n": types.UpdateCompanyDocument,
    "\n  mutation updateCompanySettings($id: ID!, $input: UpdateCompanySettings!) {\n    updateCompanySettings(id: $id, input: $input) {\n      id\n      currency\n    }\n  }\n": types.UpdateCompanySettingsDocument,
    "\n  mutation updateHubPage($companyID: ID!, $input: HubPageUpdateInput!) {\n    updateHubPage(companyID: $companyID, input: $input) {\n      companyId\n    }\n  }\n": types.UpdateHubPageDocument,
    "\n  query Invoice($invoiceID: ID!) {\n    invoice(invoiceID: $invoiceID) {\n      id\n      total\n      place\n      shortID\n      branchId\n      createdAt\n      invoiceLines {\n        id\n        title\n        subtitle\n        quantity\n        price\n        total\n      } \n      invoiceSecondaryLines {\n        id\n        title\n        price\n        description\n        invoiceCategory\n      }\n    }\n  }\n": types.InvoiceDocument,
    "\n  query InvoicesByBranch($branchId: ID!, $filter: InvoicesFilter!) {\n    invoicesByBranch(branchId: $branchId, filter: $filter) {\n      ...InvoiceItem\n    }\n  }\n": types.InvoicesByBranchDocument,
    "\n  query GET_MENU_PREVIEW($companyID: ID!) {\n    menuPreview(companyId: $companyID) {\n      id\n      type\n      url\n      menuId\n    }\n  }\n": types.Get_Menu_PreviewDocument,
    "\n  mutation updateMenuPreview($input: MenuPreviewUpdate!) {\n    updateMenuPreview(input: $input) {\n      id\n    }\n  }\n": types.UpdateMenuPreviewDocument,
    "\n  mutation CreateMenu($companyId: ID!, $input: CreateMenu!) {\n    createMenu(companyId: $companyId, input: $input) {\n      id\n      title\n    }\n  }\n": types.CreateMenuDocument,
    "\n  mutation DeleteMenu($id: ID!) {\n    deleteMenu(id: $id) {\n      id\n    }\n  }\n": types.DeleteMenuDocument,
    "\n  query GetMenuProductDetail($id: ID!) {\n    menuProduct(id: $id) {\n      id\n      section {\n        id\n        name\n        description\n      }\n      configurations {\n        id\n        type\n        title\n        values {\n          id\n          name\n          price\n        }\n      }\n    }\n  }\n": types.GetMenuProductDetailDocument,
    "\n    query GetMenuDetail($id: ID!) {\n        menu(id: $id) {\n            id\n            companyId\n            title\n            allergics\n            preferredProductPriceMode\n            sections {\n                ...MenuSectionItem\n            }\n            menuProducts {\n                ...MenuProductItem\n            }\n        }\n    }\n": types.GetMenuDetailDocument,
    "\n  query GetSections($menuId: ID!) {\n    menuSections(menuId: $menuId) {\n      id\n      name\n    }\n  }\n": types.GetSectionsDocument,
    "\n  mutation updateMenuProductEdit($id: ID!, $input: UpdateMenuProduct!) {\n    updateMenuProduct(id: $id, input: $input) {\n      id\n      section {\n        name\n      }\n    }\n  }\n": types.UpdateMenuProductEditDocument,
    "\n  mutation updateMenuEdit($menuId: ID!, $input: UpdateMenu!) {\n    updateMenu(id: $menuId, input: $input) {\n      id\n      title\n    }\n  }\n": types.UpdateMenuEditDocument,
    "\n  query MenuList($companyId: ID!, $menuSource: MenuSource!) {\n    menus(companyId: $companyId, menuSource: $menuSource) {\n      id\n      title\n    }\n  }\n": types.MenuListDocument,
    "\n  mutation CreateMenuProductConfiguration(\n    $productId: String!\n    $input: CreateProductConfiguration!\n  ) {\n    createMenuProductConfiguration(productId: $productId, input: $input) {\n      id\n    }\n  }\n": types.CreateMenuProductConfigurationDocument,
    "\n  mutation DeleteMenuProductConfiguration($id: ID!) {\n    deleteMenuProductConfiguration(id: $id)\n  }\n": types.DeleteMenuProductConfigurationDocument,
    "\n  query GetMenuProductConfiguration($id: ID!) {\n    menuProductConfiguration(id: $id) {\n      ...MenuProductConfigurationItem\n    }\n  }\n": types.GetMenuProductConfigurationDocument,
    "\n  mutation UpdateMenuProductConfiguration(\n    $id: ID!\n    $input: UpdateProductConfiguration!\n  ) {\n    updateMenuProductConfiguration(id: $id, input: $input) {\n      id\n      title\n      type\n    }\n  }\n": types.UpdateMenuProductConfigurationDocument,
    "\n  query GetProductConfiguration($productId: ID!) {\n    menuProductConfigurations(productId: $productId) {\n      id\n      type\n      title\n      values {\n        id\n        name\n        price\n      }\n    }\n  }\n": types.GetProductConfigurationDocument,
    "\n  mutation CreateMenuProductConfigurationValue(\n    $menuProductConfigurationId: ID!\n    $input: CreateMenuProductConfigurationValue!\n  ) {\n    createMenuProductConfigurationValue(\n      menuProductConfigurationId: $menuProductConfigurationId\n      input: $input\n    ) {\n      id\n      name\n      price\n    }\n  }\n": types.CreateMenuProductConfigurationValueDocument,
    "\n  mutation DeleteMenuProductConfigurationValue($id: ID!) {\n    deleteMenuProductConfigurationValue(id: $id) {\n      id\n    }\n  }\n": types.DeleteMenuProductConfigurationValueDocument,
    "\n  query MenuProductConfigurationValue($id: ID!) {\n    menuProductConfigurationValue(id: $id) {\n      id\n      name\n      price\n    }\n  }\n": types.MenuProductConfigurationValueDocument,
    "\n  mutation UpdateMenuProductConfigurationValue(\n    $id: ID!\n    $input: UpdateMenuProductConfigurationValue!\n  ) {\n    updateMenuProductConfigurationValue(id: $id, input: $input) {\n      id\n      name\n      price\n    }\n  }\n": types.UpdateMenuProductConfigurationValueDocument,
    "\n  mutation CreateMenuProduct($menuId: ID!, $input: CreateMenuProduct) {\n    createMenuProduct(menuId: $menuId, input: $input) {\n      id\n    }\n  }\n": types.CreateMenuProductDocument,
    "\n    mutation UpdateMenu($Id: ID!, $input: UpdateMenu!) {\n        updateMenu(id: $Id, input: $input) {\n            id\n            title\n        }\n    }\n": types.UpdateMenuDocument,
    "\n  mutation DeleteMenuProduct($id: ID!) {\n    deleteMenuProduct(id: $id) {\n      id\n    }\n  }\n": types.DeleteMenuProductDocument,
    "\n  query GetMenuProduct($id: ID!) {\n    menuProduct(id: $id) {\n      id\n      code\n      title\n      priceMode\n      description\n      images\n      ingredients\n      labels\n      priceMode\n      allergics\n      section {\n        id\n        name\n        description\n        code\n      }\n      configurations {\n        id\n        title\n        type\n        values {\n          id\n          name\n          price\n        }\n      }\n\n      menuId\n      sectionId\n    }\n  }\n": types.GetMenuProductDocument,
    "\n  mutation UpdateMenuProduct($id: ID!, $input: UpdateMenuProduct!) {\n    updateMenuProduct(id: $id, input: $input) {\n      id\n    }\n  }\n": types.UpdateMenuProductDocument,
    "\n  mutation createMenuSection($menuId: ID!, $input: CreateMenuSection!, $timeRuleInput: CreateMenuSectionTimeRule!) {\n    createMenuSection(menuId: $menuId, input: $input, timeRuleInput: $timeRuleInput) {\n      id\n      name\n    }\n  }\n": types.CreateMenuSectionDocument,
    "\n  mutation DeleteMenuSection($id: ID!) {\n    deleteMenuSection(id: $id)\n  }\n": types.DeleteMenuSectionDocument,
    "\n  query getMenuSection($id: ID!) {\n    menuSection(id: $id) {\n      id\n      name\n      description\n      code\n      enabled\n      timeRule{\n        __typename\n        ... on MenuSectionAlwaysTimeRule {\n          type\n        }\n      \n        __typename\n        ... on MenuSectionRangeTimeRule {\n          type\n          start\n          end\n          daysOfWeek\n          isAllDays\n        }\n      }\n    }\n  }\n": types.GetMenuSectionDocument,
    "\n  mutation updateMenuSection($id: ID!, $input: UpdateMenuSection!, $timeRuleInput: UpdateMenuSectionTimeRule!) {\n    updateMenuSection(id: $id, input: $input, timeRuleInput: $timeRuleInput) {\n      name\n      description\n    }\n  }\n": types.UpdateMenuSectionDocument,
    "\n  query GetMenuSections($menuId: ID!) {\n    menuSections(menuId: $menuId) {\n      ...MenuSectionItem\n    }\n  }\n": types.GetMenuSectionsDocument,
    "\n  query OnlineOrderBranchConfiguration($branchID: ID!) {\n    onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchSettingItem\n    }\n  }\n": types.OnlineOrderBranchConfigurationDocument,
    "\n  query OnlineOrderDashboard($branchID: ID!, $filter: OnlineOrderDashboardFilter!) {\n    onlineOrdersDashboard(branchID: $branchID, filter: $filter) {\n      ...OnlineOrderListItem\n    }\n  }\n": types.OnlineOrderDashboardDocument,
    "\n  subscription OnlineOrderDashboardSubscription($branchID: ID!) {\n    onUpdateOnlineOrder(branchID: $branchID) {\n      branchID\n      onlineOrderID\n      timestamp\n    }\n  }\n": types.OnlineOrderDashboardSubscriptionDocument,
    "\n  mutation OnlineOrderCancel($id: ID!) {\n    onlineOrderOwnerCancel(id: $id)\n  }\n": types.OnlineOrderCancelDocument,
    "\n  mutation OnlineOrderChangeDeliveryPrice($id: ID!, $deliveryPrice: Int!) {\n    onlineOrderChangeDeliveryPrice(id: $id, deliveryPrice: $deliveryPrice)\n  }\n": types.OnlineOrderChangeDeliveryPriceDocument,
    "\n  mutation OnlineOrderConfirm($id: ID!, $input: OnlineOrderOwnerConfirmInput!) {\n    onlineOrderOwnerConfirm(id: $id, input: $input)\n  }\n": types.OnlineOrderConfirmDocument,
    "\n  mutation OnlineOrderDecline($id: ID!) {\n    onlineOrderOwnerDecline(id: $id)\n  }\n": types.OnlineOrderDeclineDocument,
    "\n  mutation OnlineOrderDone($id: ID!) {\n    onlineOrderOwnerDone(id: $id)\n  }\n": types.OnlineOrderDoneDocument,
    "\n  query OnlineOrderDetail($id: ID!, $branchID: ID!) {\n    onlineOrder(id: $id) {\n      ...OnlineOrderItem\n    }\n\n    onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchSettingItem\n    }\n  }\n": types.OnlineOrderDetailDocument,
    "\n  query OnlineOrderHistory($branchID: ID!, $filter : OnlineOrderFilter!) {\n    onlineOrdersHistory(branchID: $branchID, filter: $filter) {\n      ...OnlineOrderListItem\n    }\n  }\n": types.OnlineOrderHistoryDocument,
    "\n  mutation SetupOnlineOrderBranchConfiguration($branchID: ID!) {\n    setupOnlineOrderBranchConfiguration(branchID: $branchID) {\n      branchID\n      menuID\n    }\n  }\n": types.SetupOnlineOrderBranchConfigurationDocument,
    "\n  mutation UpdateOnlineOrderConfiguration($branchID: ID!, $input: UpdateOnlineOrderConfigurationInput!) {\n    updateOnlineOrderConfiguration(branchID: $branchID, input: $input) {\n      branchID\n      menuID\n    }\n  }\n": types.UpdateOnlineOrderConfigurationDocument,
    "\n  mutation CopyOpeningTime($branchID: ID!, $sourceService: OpeningTimeServiceType!, $targetService: OpeningTimeServiceType!) {\n    copyOpeningTimes(branchID: $branchID, sourceService: $sourceService,targetService: $targetService)\n  }\n": types.CopyOpeningTimeDocument,
    "\n    mutation CreateManyOpeningTime($input: CreateManyOpeningTimeData!) {\n        createManyOpeningTime(input: $input) {\n            id\n        }\n    }\n": types.CreateManyOpeningTimeDocument,
    "\n  mutation CreateOpeningTime($input: CreateOpeningTimeData!) {\n    createOpeningTime(input: $input) {\n      id\n    }\n  }\n": types.CreateOpeningTimeDocument,
    "\n  mutation deleteOpeningTime($id: ID!) {\n    deleteOpeningTime(id: $id) {\n      id\n    }\n  }\n": types.DeleteOpeningTimeDocument,
    "\n  query OpeningTime($id: ID!) {\n    openingTime(id: $id) {\n      id\n      branchId\n      dayOfWeek\n      openTime\n      closeTime\n      note\n      isActive\n    }\n  }\n": types.OpeningTimeDocument,
    "\n  mutation UpdateOpeningTime($id: ID!, $input: UpdateOpeningTimeData!) {\n    updateOpeningTime(id: $id, input: $input) {\n        id\n        branchId\n        dayOfWeek\n        openTime\n        closeTime\n        note\n        isActive\n    }\n  }\n": types.UpdateOpeningTimeDocument,
    "\n  query OpeningTimes($branchId: ID!, $filter: OpeningTimeFilter) {\n    openingTimes(branchId: $branchId, filter: $filter) {\n      ...OpeningTimeItem\n    }\n  }\n": types.OpeningTimesDocument,
    "\n  mutation CreateTableGroup(\n    $branchID: ID!\n    $name: String!\n    $arrangementCode: String\n  ) {\n    createTableGroup(\n      branchID: $branchID\n      input: { name: $name, arrangementCode: $arrangementCode }\n    ) {\n      id\n      name\n      arrangementCode\n    }\n  }\n": types.CreateTableGroupDocument,
    "\n  mutation DeleteTableGroup($id: ID!) {\n    deleteTableGroup(id: $id) {\n      id\n      name\n      arrangementCode\n    }\n  }\n": types.DeleteTableGroupDocument,
    "\n  mutation UpdateTableGroup(\n    $id: ID!\n    $name: String!\n    $arrangementCode: String\n  ) {\n    updateTableGroup(\n      id: $id\n      input: {\n        name: $name\n        arrangementCode: $arrangementCode\n        gridLayout: {\n          mesh: {\n            rows: 0\n            columns: 0\n            entries: { row: 0, column: 0, tableId: \"\" }\n          }\n        }\n      }\n    ) {\n      id\n      name\n      arrangementCode\n    }\n  }\n": types.UpdateTableGroupDocument,
    "\n  query TableGroupsList($branchID: ID!) {\n    tableGroups(branchID: $branchID) {\n      ...TableGroupItem\n    }\n  }\n": types.TableGroupsListDocument,
    "\n  query TableReservationReportByBranch($branchId: ID!) {\n    tableReservationReport(branchID: $branchId) {\n      daily {\n        date\n        count\n      }\n      hourly {\n        hour\n        count\n      }\n    }\n  }\n": types.TableReservationReportByBranchDocument,
    "\n  query TableReservationConfirmationDecision($id: ID!, $branchID: ID!) {\n    tableReservation(id: $id) {\n      ...TableReservationItem\n    }\n    tablesByBranch(branchID: $branchID) {\n      ...TableWithGroupItem\n    }\n  }\n": types.TableReservationConfirmationDecisionDocument,
    "\n  mutation CreateTableBooking($branchId: ID!, $input: TableReservationCreate!) {\n    createTableReservation(branchId: $branchId, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n": types.CreateTableBookingDocument,
    "\n  query TableReservationDetail($id: ID!) {\n    tableReservation(id: $id) {\n      ...TableReservationItem\n    }\n  }\n": types.TableReservationDetailDocument,
    "\n  mutation TableReservationUpdate($id: ID!, $input: TableReservationUpdate!) {\n    updateTableReservation(id: $id, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n": types.TableReservationUpdateDocument,
    "\n  query BranchTableReservationInfo($companyID: ID!) {\n    tableReservationBranches(companyID: $companyID) {\n      branchId\n      branchName\n      tables\n      bookingTable\n      tableReservationSettingId\n    }\n  }\n": types.BranchTableReservationInfoDocument,
    "\n  subscription TableBookingUpdateSubscribe($branchId: ID!) {\n    tableReservationUpdateSubscribe(branchId: $branchId) {\n      event\n      tableReservation {\n        ...TableReservationItem\n      }\n    }\n  }\n": types.TableBookingUpdateSubscribeDocument,
    "\n  query TableGroups($branchId: ID!) {\n    tableGroups(branchID: $branchId) {\n      id\n      name\n      arrangementCode\n      tables {\n        id\n        name\n        seats\n        tableGroupId\n      }\n    }\n  }\n": types.TableGroupsDocument,
    "\n  query TableReservationOperative($branchId: ID!) {\n    tableReservationsOperative(branchId: $branchId) {\n      ...TableReservationItem\n    }\n    tableReservationConfiguration(branchID: $branchId) {\n      id\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n    }\n  }\n": types.TableReservationOperativeDocument,
    "\n  query TableReservations($branchId: ID!, $filter: TableReservationsFilter!) {\n    tableReservations(branchId: $branchId, filter: $filter) {\n      ...TableReservationItem\n    }\n  }\n": types.TableReservationsDocument,
    "\n  query Table($tableId: ID!) {\n    table(id: $tableId) {\n      id\n      name\n      tableGroupId\n    }\n  }\n": types.TableDocument,
    "\n  query TablesByBranch($branchId: ID!) {\n    tablesByBranch(branchID: $branchId) {\n      ...TableWithGroupItem\n    }\n  }\n": types.TablesByBranchDocument,
    "\n  mutation UpdateConfirmTableReservation(\n    $id: ID!\n    $status: TableReservationConfirmationStatus!\n  ) {\n    updateTableReservationConfirmationStatus(id: $id, status: $status) {\n      id\n      confirmationStatus\n      status\n    }\n  }\n": types.UpdateConfirmTableReservationDocument,
    "\n  mutation UpdateStatusOnTableBooking(\n    $id: ID!\n    $status: TableReservationStatus!\n  ) {\n    updateTableReservationStatus(id: $id, status: $status) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n": types.UpdateStatusOnTableBookingDocument,
    "\n  mutation UpdateTableOnTableBooking($id: ID!, $tableId: ID) {\n    updateTableReservationTable(id: $id, tableId: $tableId) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n": types.UpdateTableOnTableBookingDocument,
    "\n  mutation DeleteAllTableReservations($branchID: ID!) {\n    deleteAllTableReservations(branchId: $branchID)\n  }\n": types.DeleteAllTableReservationsDocument,
    "\n  query BranchTableBookingConfiguration($branchID: ID!) {\n    tableReservationConfiguration(branchID: $branchID) {\n      id\n      hourBlocking\n      seatLimit\n      flexDuration\n      defaultBookingTimeDuration\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n      notificationMails\n      phone\n      reservationLeadMinutes\n      reservationTraitMinutes\n      reminderTime\n      flexEnabled  \n    }\n  }\n": types.BranchTableBookingConfigurationDocument,
    "\n  mutation UpdateBranchTableBookingConfiguration(\n    $id: ID!\n    $input: TableReservationConfigurationUpdateInput!\n  ) {\n    updateTableReservationConfiguration(id: $id, input: $input) {\n      id\n      hourBlocking\n      defaultBookingTimeDuration\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n      notificationMails\n      phone\n    }\n  }\n": types.UpdateBranchTableBookingConfigurationDocument,
    "\n  mutation createTableBatch($tableGroup: ID!, $data: [CreateTable!]!) {\n    createTableBatch(tableGroup: $tableGroup, data: $data) {\n      count\n    }\n  }\n": types.CreateTableBatchDocument,
    "\n  mutation updateTableBatch($data: [BatchUpdateTableEntry!]!) {\n    updateTableBatch(data: $data) {\n      id\n    }\n  }\n": types.UpdateTableBatchDocument,
    "\n  mutation deleteTableBatch($ids: [ID!]!) {\n    deleteTableBatch(ids: $ids) {\n      count\n    }\n  }\n": types.DeleteTableBatchDocument,
    "\n  query tableGroup($id: ID!) {\n    tableGroup(id: $id) {\n      id\n      name\n      arrangementCode\n      tables {\n        ...TableItem\n      }\n    }\n  }\n": types.TableGroupDocument,
    "\n  mutation createTeam($branchId: ID!, $input: CreateTeamInput!, $memberTeam: [TeamMemberCreateInput!]!) {\n    createTeam(branchId: $branchId, input: $input, memberTeam: $memberTeam) {\n      id\n      name\n      description\n    }\n  }\n": types.CreateTeamDocument,
    "\n  mutation updateTeam($id: ID!, $input: UpdateTeamInput!, $memberTeam: [TeamMemberUpdateInput!]!) {\n    updateTeam(id: $id, input: $input, memberTeam: $memberTeam) {\n      id\n      name\n      description\n    }\n  }\n": types.UpdateTeamDocument,
    "\n  query userNotifications($companyID: ID) {\n    userNotifications(companyID: $companyID) {\n      id\n      title\n      link\n      message\n      createdAt\n    }\n  }\n": types.UserNotificationsDocument,
    "\n    mutation QuickCreateAccount($input: QuickSetupInput!) {\n        clientQuickSetup(input: $input) {\n            username\n            password\n        }\n    }\n": types.QuickCreateAccountDocument,
    "\n  query DailyInvoiceReport($companyID: ID!) {\n    analytic__invoice_report(companyID: $companyID) {\n      daily {\n        date\n        total\n        count\n      }\n    }\n  }\n": types.DailyInvoiceReportDocument,
    "\n  query Analytic__invoice_aggregated_report_by_date(\n    $companyID: ID!\n    $startDate: String!\n    $endDate: String!\n  ) {\n    analytic__invoice_aggregated_report_by_date(\n      companyID: $companyID\n      startDate: $startDate\n      endDate: $endDate\n    ) {\n      totalInvoices\n      totalAmount\n    }\n  }\n": types.Analytic__Invoice_Aggregated_Report_By_DateDocument,
    "\n  mutation ResetCurrentUserPassword(\n    $userID: ID!\n    $input: ResetCurrentUserPasswordInput!\n  ) {\n    resetCurrentUserPassword(userID: $userID, input: $input)\n  }\n": types.ResetCurrentUserPasswordDocument,
    "\n  mutation UserCompanyRoleCreate(\n    $companyID: ID!\n    $input: UserCompanyRoleCreate!\n  ) {\n    userCompanyRoleCreate(companyID: $companyID, input: $input) {\n      password\n      userCompanyRole{\n        user {\n          id\n          firstName\n          lastName\n          username\n          email\n          status\n        }\n      }\n    }\n  }\n": types.UserCompanyRoleCreateDocument,
    "\n  mutation UserCompanyRoleDelete($companyID: ID!, $userID: ID!) {\n    userCompanyRoleDelete(companyID: $companyID, userID: $userID) {\n      id\n    }\n  }\n": types.UserCompanyRoleDeleteDocument,
    "\n    mutation ResetUserPassword($userID: ID!, $companyID: ID!){\n        resetUserPassword(userID: $userID, companyID: $companyID)\n    }\n": types.ResetUserPasswordDocument,
    "\n  mutation UserCompanyRoleUpdate(\n    $companyID: ID!\n    $input: UserCompanyRoleUpdate!\n  ) {\n    userCompanyRoleUpdate(companyID: $companyID, input: $input) {\n      id\n    }\n  }\n": types.UserCompanyRoleUpdateDocument,
    "\n  query User($companyID: ID!, $userID: ID!) {\n    userCompanyRole(companyID: $companyID, userID: $userID){\n      permission\n    }\n\n    user(id: $userID) {\n      id\n      firstName\n      lastName\n      username\n      email\n      status\n    }\n  }\n": types.UserDocument,
    "\n  query UserCompanyRoles($companyID: ID!, $offset: Int!, $limit: Int!) {\n    userCompanyRoles(companyID: $companyID, offset: $offset, limit: $limit) {\n      user {\n        id\n        firstName\n        lastName\n        username\n        role\n        email\n        status\n      }\n      permission\n    }\n  }\n": types.UserCompanyRolesDocument,
    "\n  query UserCompanyRolesCount($companyID: ID!) {\n    userCompanyRolesCount(companyID: $companyID)\n  }\n": types.UserCompanyRolesCountDocument,
    "\n  query CurrentUser{\n    currentUser{\n        id\n        email\n        firstName\n        lastName\n        username\n        status\n        role\n    }\n  }\n": types.CurrentUserDocument,
    "\n  fragment PickedNailAppointmentTeamItem on PickedNailAppointmentTeam {\n    id\n    teamName\n    appointmentId\n    teamId\n    team {\n      teamMember {\n        userId\n        user {\n          id\n          username\n          lastName\n          firstName\n        }\n      }\n    }\n  }\n": types.PickedNailAppointmentTeamItemFragmentDoc,
    "\n  fragment PickedNailAppointmentEmployeeItem on PickedNailAppointmentEmployee {\n    id\n    appointmentId\n    userId\n    user {\n      id\n      username\n      firstName\n      lastName\n      userId\n    }\n  }\n": types.PickedNailAppointmentEmployeeItemFragmentDoc,
    "\n  fragment MenuItem on Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n  }\n": types.MenuItemFragmentDoc,
    "\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n": types.MenuProductConfigurationItemFragmentDoc,
    "\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n": types.MenuProductConfigurationValueItemFragmentDoc,
    "\n  fragment MenuProductItem on MenuProduct {\n    id\n    code\n    title\n    priceMode\n    description\n    images\n    ingredients\n    labels\n    priceMode\n    allergics\n    enabled\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      id\n      title\n      type\n      values {\n        ...MenuProductConfigurationValueItem\n      }\n    }\n\n    menuId\n    sectionId\n  }\n": types.MenuProductItemFragmentDoc,
    "\n  fragment MenuSectionItem on MenuSection {\n    id\n    name\n    description\n    code\n  }\n": types.MenuSectionItemFragmentDoc,
    "\n  fragment ServiceItem on NailAppointmentMenuProduct {\n    id\n    code\n    title\n    description\n    images\n    enabled\n    ingredients\n    labels\n    allergics\n    priceMode\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    appointmentMenuProductData {\n      duration\n    }\n\n    menuId\n    sectionId\n  }\n": types.ServiceItemFragmentDoc,
    "\n  fragment TeamItem on Team {\n    id\n    name\n    description\n    teamMember {\n      id\n      userId\n      user {\n        username\n        firstName\n        lastName\n      }\n    }\n  }\n": types.TeamItemFragmentDoc,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BranchItem on Branch {\n    id\n    name\n    website\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n  }\n"): (typeof documents)["\n  fragment BranchItem on Branch {\n    id\n    name\n    website\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InvoiceItem on Invoice {\n    id\n    total\n    place\n    shortID\n    invoiceLines {\n      ...InvoiceLineItem\n    }\n    \n    invoiceSecondaryLines {\n      ...InvoiceSecondaryLineItem\n    }\n    createdAt\n  }\n"): (typeof documents)["\n  fragment InvoiceItem on Invoice {\n    id\n    total\n    place\n    shortID\n    invoiceLines {\n      ...InvoiceLineItem\n    }\n    \n    invoiceSecondaryLines {\n      ...InvoiceSecondaryLineItem\n    }\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InvoiceLineItem on InvoiceLine {\n    id\n    title\n    subtitle\n    quantity\n    price\n    total\n  }\n"): (typeof documents)["\n  fragment InvoiceLineItem on InvoiceLine {\n    id\n    title\n    subtitle\n    quantity\n    price\n    total\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InvoiceSecondaryLineItem on InvoiceSecondaryLines {\n    id\n    title\n    price\n    description\n    invoiceCategory\n  }\n"): (typeof documents)["\n  fragment InvoiceSecondaryLineItem on InvoiceSecondaryLines {\n    id\n    title\n    price\n    description\n    invoiceCategory\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NailAppointmentConfigurationItem on AppointmentBranchConfiguration {\n    branchId\n    menuId\n    enable\n    hourBlocking\n    autoConfirm\n\n    appointmentLeadMinutes\n    appointmentTraitMinutes\n\n    reminderTime\n    menu {\n      title\n    }\n  }\n"): (typeof documents)["\n  fragment NailAppointmentConfigurationItem on AppointmentBranchConfiguration {\n    branchId\n    menuId\n    enable\n    hourBlocking\n    autoConfirm\n\n    appointmentLeadMinutes\n    appointmentTraitMinutes\n\n    reminderTime\n    menu {\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NailAppointmentItem on NailAppointment {\n    id\n    branchId\n    customerVariantId\n    customerVariant {\n      id\n      name\n      phone\n      email\n      customerId\n    }\n    confirmationStatus\n    startTime\n    endTime\n    message\n    teams {\n      ...PickedNailAppointmentTeamItem\n    }\n    employees {\n      ...PickedNailAppointmentEmployeeItem\n    }\n    products {\n      id\n      appointmentId\n      pickedProduct {\n        id\n        enabled\n        title\n        description\n        images\n        code\n        configurations {\n          id\n          title\n          value\n          price\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment NailAppointmentItem on NailAppointment {\n    id\n    branchId\n    customerVariantId\n    customerVariant {\n      id\n      name\n      phone\n      email\n      customerId\n    }\n    confirmationStatus\n    startTime\n    endTime\n    message\n    teams {\n      ...PickedNailAppointmentTeamItem\n    }\n    employees {\n      ...PickedNailAppointmentEmployeeItem\n    }\n    products {\n      id\n      appointmentId\n      pickedProduct {\n        id\n        enabled\n        title\n        description\n        images\n        code\n        configurations {\n          id\n          title\n          value\n          price\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderBranchSettingItem on OnlineOrderBranchConfiguration {\n    branchID\n    menuID\n\n    pickupEnable\n    autoPickupConfirm  \n\n    defaultPreparingTime\n\n    pickupDiscountAmount\n    pickupDiscountType\n\n    categoryStyle\n\n    deliveryEnable\n    autoDeliveryConfirm  \n    deliveryRadiusLimit\n    minimumOrderAmountForDelivery\n    deliveryTableData {\n      id\n      deliveryDistance\n      deliveryTime\n      deliveryFee\n    }\n  }\n"): (typeof documents)["\n  fragment OnlineOrderBranchSettingItem on OnlineOrderBranchConfiguration {\n    branchID\n    menuID\n\n    pickupEnable\n    autoPickupConfirm  \n\n    defaultPreparingTime\n\n    pickupDiscountAmount\n    pickupDiscountType\n\n    categoryStyle\n\n    deliveryEnable\n    autoDeliveryConfirm  \n    deliveryRadiusLimit\n    minimumOrderAmountForDelivery\n    deliveryTableData {\n      id\n      deliveryDistance\n      deliveryTime\n      deliveryFee\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {\n    id\n    state\n    timestamp\n  }\n"): (typeof documents)["\n  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {\n    id\n    state\n    timestamp\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderProductsItem on OnlineOrderProduct {\n    id\n    title\n    quantity\n\n    menuProduct {\n      id\n      images\n    }\n\n    configurations {\n      id\n      title\n      value\n      price\n\n      menuProductConfigurationId\n      menuProductConfigurationValueId\n    }\n  }\n"): (typeof documents)["\n  fragment OnlineOrderProductsItem on OnlineOrderProduct {\n    id\n    title\n    quantity\n\n    menuProduct {\n      id\n      images\n    }\n\n    configurations {\n      id\n      title\n      value\n      price\n\n      menuProductConfigurationId\n      menuProductConfigurationValueId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderItem on OnlineOrder {\n    id\n    shortID\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    distanceToRestaurant\n    longitude\n    latitude\n\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    pickupDiscount\n    totalPrice\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    onlineOrderProducts {\n      ...OnlineOrderProductsItem\n    }\n\n    userCreatedAt\n    createdAt\n  }\n"): (typeof documents)["\n  fragment OnlineOrderItem on OnlineOrder {\n    id\n    shortID\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    distanceToRestaurant\n    longitude\n    latitude\n\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    pickupDiscount\n    totalPrice\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    onlineOrderProducts {\n      ...OnlineOrderProductsItem\n    }\n\n    userCreatedAt\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderListItem on OnlineOrder {\n    id\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    distanceToRestaurant\n    longitude\n    latitude\n\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    totalPrice\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    userCreatedAt\n    createdAt\n\n    _count {\n      onlineOrderProducts\n    }\n  }\n"): (typeof documents)["\n  fragment OnlineOrderListItem on OnlineOrder {\n    id\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    distanceToRestaurant\n    longitude\n    latitude\n\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    totalPrice\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    userCreatedAt\n    createdAt\n\n    _count {\n      onlineOrderProducts\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OpeningTimeItem on OpeningTime {\n    id\n    branchId\n    dayOfWeek\n    openTime\n    closeTime\n    note\n    isActive\n  }\n"): (typeof documents)["\n  fragment OpeningTimeItem on OpeningTime {\n    id\n    branchId\n    dayOfWeek\n    openTime\n    closeTime\n    note\n    isActive\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TableItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n  }\n"): (typeof documents)["\n  fragment TableItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TableWithGroupItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n    tableGroup {\n      id\n      name\n      arrangementCode\n    }\n  }\n"): (typeof documents)["\n  fragment TableWithGroupItem on Table {\n    id\n    name\n    seats\n    tableGroupId\n    tableGroup {\n      id\n      name\n      arrangementCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TableGroupItem on TableGroup {\n    id\n    name\n    arrangementCode\n  }\n"): (typeof documents)["\n  fragment TableGroupItem on TableGroup {\n    id\n    name\n    arrangementCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TableReservationItem on TableReservation {\n    id\n    platformType\n    tableId\n    guestName\n    status\n    email\n    message\n    startTime\n    phone\n    seats\n    confirmationStatus\n    timezone\n    flexTime\n  }\n"): (typeof documents)["\n  fragment TableReservationItem on TableReservation {\n    id\n    platformType\n    tableId\n    guestName\n    status\n    email\n    message\n    startTime\n    phone\n    seats\n    confirmationStatus\n    timezone\n    flexTime\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserCompanyRoleItem on UserCompanyRole {\n    id\n    user {\n      id\n      firstName\n      lastName\n      username\n      role\n      email\n      status\n    }\n  }\n"): (typeof documents)["\n  fragment UserCompanyRoleItem on UserCompanyRole {\n    id\n    user {\n      id\n      firstName\n      lastName\n      username\n      role\n      email\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdatePushToken($token: String!) {\n    pushNotificationRegisterToken(token: $token)\n  }\n"): (typeof documents)["\n  mutation UpdatePushToken($token: String!) {\n    pushNotificationRegisterToken(token: $token)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query PushTokens {\n    pushNotificationTokens\n  }\n"): (typeof documents)["\n  query PushTokens {\n    pushNotificationTokens\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInternalAPIKey($input: CreateInternalAPIKeyInput!) {\n    generateInternalAPIKey(input: $input)\n  }\n"): (typeof documents)["\n  mutation CreateInternalAPIKey($input: CreateInternalAPIKeyInput!) {\n    generateInternalAPIKey(input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ApiKeyListPage {\n    internalAPIKeys {\n      id\n      key\n      description\n      createdAt\n      updatedAt\n    }\n  }\n"): (typeof documents)["\n  query ApiKeyListPage {\n    internalAPIKeys {\n      id\n      key\n      description\n      createdAt\n      updatedAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateCompany($input: CreateCompanyInput!) {\n    createCompany(input: $input) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation CreateCompany($input: CreateCompanyInput!) {\n    createCompany(input: $input) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AppointmentBranchConfiguration($branchId: ID!) {\n    appointmentBranchConfiguration(branchId: $branchId) {\n      ...NailAppointmentConfigurationItem\n    }\n  }\n"): (typeof documents)["\n  query AppointmentBranchConfiguration($branchId: ID!) {\n    appointmentBranchConfiguration(branchId: $branchId) {\n      ...NailAppointmentConfigurationItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InitiateAppointmentBranchConfiguration($branchId: ID!) {\n    initiateAppointmentBranchConfiguration(branchId: $branchId) {\n      branchId\n      enable\n    }\n  }\n"): (typeof documents)["\n  mutation InitiateAppointmentBranchConfiguration($branchId: ID!) {\n    initiateAppointmentBranchConfiguration(branchId: $branchId) {\n      branchId\n      enable\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateAppointmentBranchConfiguration($branchId: ID!, $input: AppointmentConfigurationUpdateInput!) {\n    updateAppointmentBranchConfiguration(branchId: $branchId, input: $input) {\n      branchId\n      enable\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateAppointmentBranchConfiguration($branchId: ID!, $input: AppointmentConfigurationUpdateInput!) {\n    updateAppointmentBranchConfiguration(branchId: $branchId, input: $input) {\n      branchId\n      enable\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation assignEmployeeForAppointment($appointmentId: ID!, $userId: ID!) {\n    assignEmployeeForAppointment(appointmentId: $appointmentId, userId: $userId) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation assignEmployeeForAppointment($appointmentId: ID!, $userId: ID!) {\n    assignEmployeeForAppointment(appointmentId: $appointmentId, userId: $userId) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query CustomerHistory($id: ID!) {\n        customerAppointmentHistory(id: $id) {\n            appointmentId\n            detail\n            time\n        }\n    }\n"): (typeof documents)["\n    query CustomerHistory($id: ID!) {\n        customerAppointmentHistory(id: $id) {\n            appointmentId\n            detail\n            time\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query NailAppointment($id: ID!) {\n    nailAppointment(id: $id) {\n      ...NailAppointmentItem\n    }\n  }\n"): (typeof documents)["\n  query NailAppointment($id: ID!) {\n    nailAppointment(id: $id) {\n      ...NailAppointmentItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation switchTeamForAppointment($appointmentId: ID!, $teamId: ID!) {\n    switchTeamForAppointment(appointmentId: $appointmentId, teamId: $teamId) {\n      id\n      teamName\n      appointmentId\n      teamId\n    }\n  }\n"): (typeof documents)["\n  mutation switchTeamForAppointment($appointmentId: ID!, $teamId: ID!) {\n    switchTeamForAppointment(appointmentId: $appointmentId, teamId: $teamId) {\n      id\n      teamName\n      appointmentId\n      teamId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateAppointmentConfirmationStatus($id: ID!, $status: NailAppointmentConfirmationStatus!) {\n    updateAppointmentConfirmationStatus(id: $id, status: $status) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateAppointmentConfirmationStatus($id: ID!, $status: NailAppointmentConfirmationStatus!) {\n    updateAppointmentConfirmationStatus(id: $id, status: $status) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription AppointmentUpdateSubscribe($branchID: ID!) {\n    appointmentUpdateSubscribe(branchID: $branchID) {\n      event\n      appointment {\n        ...NailAppointmentItem\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription AppointmentUpdateSubscribe($branchID: ID!) {\n    appointmentUpdateSubscribe(branchID: $branchID) {\n      event\n      appointment {\n        ...NailAppointmentItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query NailAppointments($branchId: ID!, $filter: NailAppointmentFilter!) {\n        nailAppointments(branchId: $branchId, filter: $filter) {\n            ...NailAppointmentItem\n        }\n    }\n"): (typeof documents)["\n    query NailAppointments($branchId: ID!, $filter: NailAppointmentFilter!) {\n        nailAppointments(branchId: $branchId, filter: $filter) {\n            ...NailAppointmentItem\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetServiceDetail($id: ID!) {\n    serviceMenu(id: $id) {\n      id\n      companyId\n      title\n      menuSource\n      sections {\n        ...MenuSectionItem\n      }\n      menuProducts {\n        ...ServiceItem\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetServiceDetail($id: ID!) {\n    serviceMenu(id: $id) {\n      id\n      companyId\n      title\n      menuSource\n      sections {\n        ...MenuSectionItem\n      }\n      menuProducts {\n        ...ServiceItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Teams($branchId: ID!) {\n    teams(branchId: $branchId) {\n      ...TeamItem\n    }\n  }\n"): (typeof documents)["\n  query Teams($branchId: ID!) {\n    teams(branchId: $branchId) {\n      ...TeamItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Team($id: ID!) {\n    team(id: $id) {\n      ...TeamItem\n    }\n  }\n"): (typeof documents)["\n  query Team($id: ID!) {\n    team(id: $id) {\n      ...TeamItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAppointmentMenuProduct(\n    $menuID: ID!\n    $input: CreateAppointmentMenuProductInput!\n    $teamIds: [String!]!\n  ) {\n    createAppointmentMenuProduct(menuID: $menuID, input: $input, teamIds: $teamIds) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAppointmentMenuProduct(\n    $menuID: ID!\n    $input: CreateAppointmentMenuProductInput!\n    $teamIds: [String!]!\n  ) {\n    createAppointmentMenuProduct(menuID: $menuID, input: $input, teamIds: $teamIds) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAppointmentMenuProductDataTeam($teamId: ID!, $serviceProductId: ID!) {\n    createAppointmentMenuProductDataTeam(teamId: $teamId, serviceProductId: $serviceProductId) {\n      id\n      teamId\n      menuProductDataId\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAppointmentMenuProductDataTeam($teamId: ID!, $serviceProductId: ID!) {\n    createAppointmentMenuProductDataTeam(teamId: $teamId, serviceProductId: $serviceProductId) {\n      id\n      teamId\n      menuProductDataId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAppointmentMenuProductDataTeam($id: ID!) {\n    deleteAppointmentMenuProductDataTeam(id: $id) {\n      id\n      teamId\n      menuProductDataId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteAppointmentMenuProductDataTeam($id: ID!) {\n    deleteAppointmentMenuProductDataTeam(id: $id) {\n      id\n      teamId\n      menuProductDataId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query appointmentMenuProduct($id: ID!) {\n    appointmentMenuProduct(id: $id) {\n      id\n      code\n      title\n      priceMode\n      description\n      images\n      ingredients\n      labels\n      priceMode\n      allergics\n      section {\n        id\n        name\n        description\n        code\n      }\n      configurations {\n        id\n        title\n        type\n        values {\n          id\n          name\n          price\n        }\n      }\n\n      appointmentMenuProductData {\n        duration\n        nailAppointmentMenuProductTeams {\n          id\n          teamId\n          menuProductDataId\n        }\n      }\n\n      menuId\n      sectionId\n    }\n  }\n"): (typeof documents)["\n  query appointmentMenuProduct($id: ID!) {\n    appointmentMenuProduct(id: $id) {\n      id\n      code\n      title\n      priceMode\n      description\n      images\n      ingredients\n      labels\n      priceMode\n      allergics\n      section {\n        id\n        name\n        description\n        code\n      }\n      configurations {\n        id\n        title\n        type\n        values {\n          id\n          name\n          price\n        }\n      }\n\n      appointmentMenuProductData {\n        duration\n        nailAppointmentMenuProductTeams {\n          id\n          teamId\n          menuProductDataId\n        }\n      }\n\n      menuId\n      sectionId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateAppointmentMenuProduct($id: ID!, $input: UpdateAppointmentMenuProductInput!) {\n        updateAppointmentMenuProduct(id: $id, input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation UpdateAppointmentMenuProduct($id: ID!, $input: UpdateAppointmentMenuProductInput!) {\n        updateAppointmentMenuProduct(id: $id, input: $input) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBanner(\n    $companyID: ID!\n    $input: CreateBannerData!\n    $timeRuleInput: CreateBannerTimeRule!\n    $contentInput: CreateBannerContent!\n  ) {\n    createBanner(\n      companyID: $companyID\n      input: $input\n      timeRuleInput: $timeRuleInput\n      contentInput: $contentInput\n    ) {\n      title\n      viewCount\n      clickCount\n      enabled\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          backgroundColor\n          foregroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateBanner(\n    $companyID: ID!\n    $input: CreateBannerData!\n    $timeRuleInput: CreateBannerTimeRule!\n    $contentInput: CreateBannerContent!\n  ) {\n    createBanner(\n      companyID: $companyID\n      input: $input\n      timeRuleInput: $timeRuleInput\n      contentInput: $contentInput\n    ) {\n      title\n      viewCount\n      clickCount\n      enabled\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          backgroundColor\n          foregroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Banners(\n    $companyID: String!\n    $offset: Int!\n    $limit: Int!\n    $filter: BannerStatusFilter\n  ) {\n    banners(\n      companyId: $companyID\n      offset: $offset\n      limit: $limit\n      filter: $filter\n    ) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Banners(\n    $companyID: String!\n    $offset: Int!\n    $limit: Int!\n    $filter: BannerStatusFilter\n  ) {\n    banners(\n      companyId: $companyID\n      offset: $offset\n      limit: $limit\n      filter: $filter\n    ) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteBanner(\n    $bannerId: String!\n  ) {\n    deleteBanner(\n      bannerId: $bannerId\n    ) {\n      bannerId\n      title\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteBanner(\n    $bannerId: String!\n  ) {\n    deleteBanner(\n      bannerId: $bannerId\n    ) {\n      bannerId\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateBanner(\n    $bannerId: ID!\n    $input: UpdateBannerData!\n    $timeRuleInput: UpdateBannerTimeRule!\n    $contentInput: UpdateBannerContent!\n  ) {\n    updateBanner(\n      bannerId: $bannerId\n      input: $input\n      timeRuleInput: $timeRuleInput\n      contentInput: $contentInput\n    ) {\n      title\n      viewCount\n      clickCount\n      enabled\n      content {\n        __typename\n        ... on TopContent {\n          text\n        }\n        ... on OverlayContent {\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateBanner(\n    $bannerId: ID!\n    $input: UpdateBannerData!\n    $timeRuleInput: UpdateBannerTimeRule!\n    $contentInput: UpdateBannerContent!\n  ) {\n    updateBanner(\n      bannerId: $bannerId\n      input: $input\n      timeRuleInput: $timeRuleInput\n      contentInput: $contentInput\n    ) {\n      title\n      viewCount\n      clickCount\n      enabled\n      content {\n        __typename\n        ... on TopContent {\n          text\n        }\n        ... on OverlayContent {\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Banner($id: ID!) {\n    banner(id: $id) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          backgroundColor\n          foregroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Banner($id: ID!) {\n    banner(id: $id) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          backgroundColor\n          foregroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateBranch($companyId: ID!, $input: CreateBranch!) {\n    createBranch(companyId: $companyId, input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateBranch($companyId: ID!, $input: CreateBranch!) {\n    createBranch(companyId: $companyId, input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteBranch($id: ID!) {\n    deleteBranch(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deleteBranch($id: ID!) {\n    deleteBranch(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation FetchBranchCoordinate($branchID: ID!) {\n    automaticFetchBranchCoordinate(branchID: $branchID)\n  }\n"): (typeof documents)["\n  mutation FetchBranchCoordinate($branchID: ID!) {\n    automaticFetchBranchCoordinate(branchID: $branchID)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Branch($id: ID!) {\n        branch(id: $id) {\n            name\n            website\n            streetAddress\n            addressLocality\n            addressRegion\n            postalCode\n            addressCountry\n\n            latitude\n            longitude\n            contactPhone\n        }\n    }\n"): (typeof documents)["\n    query Branch($id: ID!) {\n        branch(id: $id) {\n            name\n            website\n            streetAddress\n            addressLocality\n            addressRegion\n            postalCode\n            addressCountry\n\n            latitude\n            longitude\n            contactPhone\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateBranch($id: ID!, $input: UpdateBranch!) {\n    updateBranch(id: $id, input: $input) {\n      name\n      streetAddress\n      addressLocality\n      addressRegion\n      postalCode\n      addressCountry\n    }\n  }\n"): (typeof documents)["\n  mutation updateBranch($id: ID!, $input: UpdateBranch!) {\n    updateBranch(id: $id, input: $input) {\n      name\n      streetAddress\n      addressLocality\n      addressRegion\n      postalCode\n      addressCountry\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CompanyBranches($companyId: ID!) {\n    branches(companyId: $companyId) {\n      ...BranchItem\n    }\n  }\n"): (typeof documents)["\n  query CompanyBranches($companyId: ID!) {\n    branches(companyId: $companyId) {\n      ...BranchItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Company($id: ID!) {\n        company(id: $id) {\n            id\n            branch {\n                id\n            }\n            settings {\n                id\n                currency\n            }\n            hubPage {\n                companyId\n                subDomain\n                description\n                banner\n            }\n        }\n    }\n"): (typeof documents)["\n    query Company($id: ID!) {\n        company(id: $id) {\n            id\n            branch {\n                id\n            }\n            settings {\n                id\n                currency\n            }\n            hubPage {\n                companyId\n                subDomain\n                description\n                banner\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCompany($companyID: ID!, $input: UpdateCompanyInput!) {\n    updateCompany(companyID: $companyID, input: $input) {\n      id\n      logo\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation updateCompany($companyID: ID!, $input: UpdateCompanyInput!) {\n    updateCompany(companyID: $companyID, input: $input) {\n      id\n      logo\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCompanySettings($id: ID!, $input: UpdateCompanySettings!) {\n    updateCompanySettings(id: $id, input: $input) {\n      id\n      currency\n    }\n  }\n"): (typeof documents)["\n  mutation updateCompanySettings($id: ID!, $input: UpdateCompanySettings!) {\n    updateCompanySettings(id: $id, input: $input) {\n      id\n      currency\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateHubPage($companyID: ID!, $input: HubPageUpdateInput!) {\n    updateHubPage(companyID: $companyID, input: $input) {\n      companyId\n    }\n  }\n"): (typeof documents)["\n  mutation updateHubPage($companyID: ID!, $input: HubPageUpdateInput!) {\n    updateHubPage(companyID: $companyID, input: $input) {\n      companyId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Invoice($invoiceID: ID!) {\n    invoice(invoiceID: $invoiceID) {\n      id\n      total\n      place\n      shortID\n      branchId\n      createdAt\n      invoiceLines {\n        id\n        title\n        subtitle\n        quantity\n        price\n        total\n      } \n      invoiceSecondaryLines {\n        id\n        title\n        price\n        description\n        invoiceCategory\n      }\n    }\n  }\n"): (typeof documents)["\n  query Invoice($invoiceID: ID!) {\n    invoice(invoiceID: $invoiceID) {\n      id\n      total\n      place\n      shortID\n      branchId\n      createdAt\n      invoiceLines {\n        id\n        title\n        subtitle\n        quantity\n        price\n        total\n      } \n      invoiceSecondaryLines {\n        id\n        title\n        price\n        description\n        invoiceCategory\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InvoicesByBranch($branchId: ID!, $filter: InvoicesFilter!) {\n    invoicesByBranch(branchId: $branchId, filter: $filter) {\n      ...InvoiceItem\n    }\n  }\n"): (typeof documents)["\n  query InvoicesByBranch($branchId: ID!, $filter: InvoicesFilter!) {\n    invoicesByBranch(branchId: $branchId, filter: $filter) {\n      ...InvoiceItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_MENU_PREVIEW($companyID: ID!) {\n    menuPreview(companyId: $companyID) {\n      id\n      type\n      url\n      menuId\n    }\n  }\n"): (typeof documents)["\n  query GET_MENU_PREVIEW($companyID: ID!) {\n    menuPreview(companyId: $companyID) {\n      id\n      type\n      url\n      menuId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateMenuPreview($input: MenuPreviewUpdate!) {\n    updateMenuPreview(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateMenuPreview($input: MenuPreviewUpdate!) {\n    updateMenuPreview(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMenu($companyId: ID!, $input: CreateMenu!) {\n    createMenu(companyId: $companyId, input: $input) {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMenu($companyId: ID!, $input: CreateMenu!) {\n    createMenu(companyId: $companyId, input: $input) {\n      id\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMenu($id: ID!) {\n    deleteMenu(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMenu($id: ID!) {\n    deleteMenu(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMenuProductDetail($id: ID!) {\n    menuProduct(id: $id) {\n      id\n      section {\n        id\n        name\n        description\n      }\n      configurations {\n        id\n        type\n        title\n        values {\n          id\n          name\n          price\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMenuProductDetail($id: ID!) {\n    menuProduct(id: $id) {\n      id\n      section {\n        id\n        name\n        description\n      }\n      configurations {\n        id\n        type\n        title\n        values {\n          id\n          name\n          price\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetMenuDetail($id: ID!) {\n        menu(id: $id) {\n            id\n            companyId\n            title\n            allergics\n            preferredProductPriceMode\n            sections {\n                ...MenuSectionItem\n            }\n            menuProducts {\n                ...MenuProductItem\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetMenuDetail($id: ID!) {\n        menu(id: $id) {\n            id\n            companyId\n            title\n            allergics\n            preferredProductPriceMode\n            sections {\n                ...MenuSectionItem\n            }\n            menuProducts {\n                ...MenuProductItem\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetSections($menuId: ID!) {\n    menuSections(menuId: $menuId) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetSections($menuId: ID!) {\n    menuSections(menuId: $menuId) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateMenuProductEdit($id: ID!, $input: UpdateMenuProduct!) {\n    updateMenuProduct(id: $id, input: $input) {\n      id\n      section {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateMenuProductEdit($id: ID!, $input: UpdateMenuProduct!) {\n    updateMenuProduct(id: $id, input: $input) {\n      id\n      section {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateMenuEdit($menuId: ID!, $input: UpdateMenu!) {\n    updateMenu(id: $menuId, input: $input) {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  mutation updateMenuEdit($menuId: ID!, $input: UpdateMenu!) {\n    updateMenu(id: $menuId, input: $input) {\n      id\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MenuList($companyId: ID!, $menuSource: MenuSource!) {\n    menus(companyId: $companyId, menuSource: $menuSource) {\n      id\n      title\n    }\n  }\n"): (typeof documents)["\n  query MenuList($companyId: ID!, $menuSource: MenuSource!) {\n    menus(companyId: $companyId, menuSource: $menuSource) {\n      id\n      title\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMenuProductConfiguration(\n    $productId: String!\n    $input: CreateProductConfiguration!\n  ) {\n    createMenuProductConfiguration(productId: $productId, input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMenuProductConfiguration(\n    $productId: String!\n    $input: CreateProductConfiguration!\n  ) {\n    createMenuProductConfiguration(productId: $productId, input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMenuProductConfiguration($id: ID!) {\n    deleteMenuProductConfiguration(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteMenuProductConfiguration($id: ID!) {\n    deleteMenuProductConfiguration(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMenuProductConfiguration($id: ID!) {\n    menuProductConfiguration(id: $id) {\n      ...MenuProductConfigurationItem\n    }\n  }\n"): (typeof documents)["\n  query GetMenuProductConfiguration($id: ID!) {\n    menuProductConfiguration(id: $id) {\n      ...MenuProductConfigurationItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMenuProductConfiguration(\n    $id: ID!\n    $input: UpdateProductConfiguration!\n  ) {\n    updateMenuProductConfiguration(id: $id, input: $input) {\n      id\n      title\n      type\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMenuProductConfiguration(\n    $id: ID!\n    $input: UpdateProductConfiguration!\n  ) {\n    updateMenuProductConfiguration(id: $id, input: $input) {\n      id\n      title\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetProductConfiguration($productId: ID!) {\n    menuProductConfigurations(productId: $productId) {\n      id\n      type\n      title\n      values {\n        id\n        name\n        price\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetProductConfiguration($productId: ID!) {\n    menuProductConfigurations(productId: $productId) {\n      id\n      type\n      title\n      values {\n        id\n        name\n        price\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMenuProductConfigurationValue(\n    $menuProductConfigurationId: ID!\n    $input: CreateMenuProductConfigurationValue!\n  ) {\n    createMenuProductConfigurationValue(\n      menuProductConfigurationId: $menuProductConfigurationId\n      input: $input\n    ) {\n      id\n      name\n      price\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMenuProductConfigurationValue(\n    $menuProductConfigurationId: ID!\n    $input: CreateMenuProductConfigurationValue!\n  ) {\n    createMenuProductConfigurationValue(\n      menuProductConfigurationId: $menuProductConfigurationId\n      input: $input\n    ) {\n      id\n      name\n      price\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMenuProductConfigurationValue($id: ID!) {\n    deleteMenuProductConfigurationValue(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMenuProductConfigurationValue($id: ID!) {\n    deleteMenuProductConfigurationValue(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query MenuProductConfigurationValue($id: ID!) {\n    menuProductConfigurationValue(id: $id) {\n      id\n      name\n      price\n    }\n  }\n"): (typeof documents)["\n  query MenuProductConfigurationValue($id: ID!) {\n    menuProductConfigurationValue(id: $id) {\n      id\n      name\n      price\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMenuProductConfigurationValue(\n    $id: ID!\n    $input: UpdateMenuProductConfigurationValue!\n  ) {\n    updateMenuProductConfigurationValue(id: $id, input: $input) {\n      id\n      name\n      price\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMenuProductConfigurationValue(\n    $id: ID!\n    $input: UpdateMenuProductConfigurationValue!\n  ) {\n    updateMenuProductConfigurationValue(id: $id, input: $input) {\n      id\n      name\n      price\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateMenuProduct($menuId: ID!, $input: CreateMenuProduct) {\n    createMenuProduct(menuId: $menuId, input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateMenuProduct($menuId: ID!, $input: CreateMenuProduct) {\n    createMenuProduct(menuId: $menuId, input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation UpdateMenu($Id: ID!, $input: UpdateMenu!) {\n        updateMenu(id: $Id, input: $input) {\n            id\n            title\n        }\n    }\n"): (typeof documents)["\n    mutation UpdateMenu($Id: ID!, $input: UpdateMenu!) {\n        updateMenu(id: $Id, input: $input) {\n            id\n            title\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMenuProduct($id: ID!) {\n    deleteMenuProduct(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMenuProduct($id: ID!) {\n    deleteMenuProduct(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMenuProduct($id: ID!) {\n    menuProduct(id: $id) {\n      id\n      code\n      title\n      priceMode\n      description\n      images\n      ingredients\n      labels\n      priceMode\n      allergics\n      section {\n        id\n        name\n        description\n        code\n      }\n      configurations {\n        id\n        title\n        type\n        values {\n          id\n          name\n          price\n        }\n      }\n\n      menuId\n      sectionId\n    }\n  }\n"): (typeof documents)["\n  query GetMenuProduct($id: ID!) {\n    menuProduct(id: $id) {\n      id\n      code\n      title\n      priceMode\n      description\n      images\n      ingredients\n      labels\n      priceMode\n      allergics\n      section {\n        id\n        name\n        description\n        code\n      }\n      configurations {\n        id\n        title\n        type\n        values {\n          id\n          name\n          price\n        }\n      }\n\n      menuId\n      sectionId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMenuProduct($id: ID!, $input: UpdateMenuProduct!) {\n    updateMenuProduct(id: $id, input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMenuProduct($id: ID!, $input: UpdateMenuProduct!) {\n    updateMenuProduct(id: $id, input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createMenuSection($menuId: ID!, $input: CreateMenuSection!, $timeRuleInput: CreateMenuSectionTimeRule!) {\n    createMenuSection(menuId: $menuId, input: $input, timeRuleInput: $timeRuleInput) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  mutation createMenuSection($menuId: ID!, $input: CreateMenuSection!, $timeRuleInput: CreateMenuSectionTimeRule!) {\n    createMenuSection(menuId: $menuId, input: $input, timeRuleInput: $timeRuleInput) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMenuSection($id: ID!) {\n    deleteMenuSection(id: $id)\n  }\n"): (typeof documents)["\n  mutation DeleteMenuSection($id: ID!) {\n    deleteMenuSection(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMenuSection($id: ID!) {\n    menuSection(id: $id) {\n      id\n      name\n      description\n      code\n      enabled\n      timeRule{\n        __typename\n        ... on MenuSectionAlwaysTimeRule {\n          type\n        }\n      \n        __typename\n        ... on MenuSectionRangeTimeRule {\n          type\n          start\n          end\n          daysOfWeek\n          isAllDays\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getMenuSection($id: ID!) {\n    menuSection(id: $id) {\n      id\n      name\n      description\n      code\n      enabled\n      timeRule{\n        __typename\n        ... on MenuSectionAlwaysTimeRule {\n          type\n        }\n      \n        __typename\n        ... on MenuSectionRangeTimeRule {\n          type\n          start\n          end\n          daysOfWeek\n          isAllDays\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateMenuSection($id: ID!, $input: UpdateMenuSection!, $timeRuleInput: UpdateMenuSectionTimeRule!) {\n    updateMenuSection(id: $id, input: $input, timeRuleInput: $timeRuleInput) {\n      name\n      description\n    }\n  }\n"): (typeof documents)["\n  mutation updateMenuSection($id: ID!, $input: UpdateMenuSection!, $timeRuleInput: UpdateMenuSectionTimeRule!) {\n    updateMenuSection(id: $id, input: $input, timeRuleInput: $timeRuleInput) {\n      name\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMenuSections($menuId: ID!) {\n    menuSections(menuId: $menuId) {\n      ...MenuSectionItem\n    }\n  }\n"): (typeof documents)["\n  query GetMenuSections($menuId: ID!) {\n    menuSections(menuId: $menuId) {\n      ...MenuSectionItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OnlineOrderBranchConfiguration($branchID: ID!) {\n    onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchSettingItem\n    }\n  }\n"): (typeof documents)["\n  query OnlineOrderBranchConfiguration($branchID: ID!) {\n    onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchSettingItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OnlineOrderDashboard($branchID: ID!, $filter: OnlineOrderDashboardFilter!) {\n    onlineOrdersDashboard(branchID: $branchID, filter: $filter) {\n      ...OnlineOrderListItem\n    }\n  }\n"): (typeof documents)["\n  query OnlineOrderDashboard($branchID: ID!, $filter: OnlineOrderDashboardFilter!) {\n    onlineOrdersDashboard(branchID: $branchID, filter: $filter) {\n      ...OnlineOrderListItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription OnlineOrderDashboardSubscription($branchID: ID!) {\n    onUpdateOnlineOrder(branchID: $branchID) {\n      branchID\n      onlineOrderID\n      timestamp\n    }\n  }\n"): (typeof documents)["\n  subscription OnlineOrderDashboardSubscription($branchID: ID!) {\n    onUpdateOnlineOrder(branchID: $branchID) {\n      branchID\n      onlineOrderID\n      timestamp\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnlineOrderCancel($id: ID!) {\n    onlineOrderOwnerCancel(id: $id)\n  }\n"): (typeof documents)["\n  mutation OnlineOrderCancel($id: ID!) {\n    onlineOrderOwnerCancel(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnlineOrderChangeDeliveryPrice($id: ID!, $deliveryPrice: Int!) {\n    onlineOrderChangeDeliveryPrice(id: $id, deliveryPrice: $deliveryPrice)\n  }\n"): (typeof documents)["\n  mutation OnlineOrderChangeDeliveryPrice($id: ID!, $deliveryPrice: Int!) {\n    onlineOrderChangeDeliveryPrice(id: $id, deliveryPrice: $deliveryPrice)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnlineOrderConfirm($id: ID!, $input: OnlineOrderOwnerConfirmInput!) {\n    onlineOrderOwnerConfirm(id: $id, input: $input)\n  }\n"): (typeof documents)["\n  mutation OnlineOrderConfirm($id: ID!, $input: OnlineOrderOwnerConfirmInput!) {\n    onlineOrderOwnerConfirm(id: $id, input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnlineOrderDecline($id: ID!) {\n    onlineOrderOwnerDecline(id: $id)\n  }\n"): (typeof documents)["\n  mutation OnlineOrderDecline($id: ID!) {\n    onlineOrderOwnerDecline(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnlineOrderDone($id: ID!) {\n    onlineOrderOwnerDone(id: $id)\n  }\n"): (typeof documents)["\n  mutation OnlineOrderDone($id: ID!) {\n    onlineOrderOwnerDone(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OnlineOrderDetail($id: ID!, $branchID: ID!) {\n    onlineOrder(id: $id) {\n      ...OnlineOrderItem\n    }\n\n    onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchSettingItem\n    }\n  }\n"): (typeof documents)["\n  query OnlineOrderDetail($id: ID!, $branchID: ID!) {\n    onlineOrder(id: $id) {\n      ...OnlineOrderItem\n    }\n\n    onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchSettingItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OnlineOrderHistory($branchID: ID!, $filter : OnlineOrderFilter!) {\n    onlineOrdersHistory(branchID: $branchID, filter: $filter) {\n      ...OnlineOrderListItem\n    }\n  }\n"): (typeof documents)["\n  query OnlineOrderHistory($branchID: ID!, $filter : OnlineOrderFilter!) {\n    onlineOrdersHistory(branchID: $branchID, filter: $filter) {\n      ...OnlineOrderListItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation SetupOnlineOrderBranchConfiguration($branchID: ID!) {\n    setupOnlineOrderBranchConfiguration(branchID: $branchID) {\n      branchID\n      menuID\n    }\n  }\n"): (typeof documents)["\n  mutation SetupOnlineOrderBranchConfiguration($branchID: ID!) {\n    setupOnlineOrderBranchConfiguration(branchID: $branchID) {\n      branchID\n      menuID\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOnlineOrderConfiguration($branchID: ID!, $input: UpdateOnlineOrderConfigurationInput!) {\n    updateOnlineOrderConfiguration(branchID: $branchID, input: $input) {\n      branchID\n      menuID\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOnlineOrderConfiguration($branchID: ID!, $input: UpdateOnlineOrderConfigurationInput!) {\n    updateOnlineOrderConfiguration(branchID: $branchID, input: $input) {\n      branchID\n      menuID\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CopyOpeningTime($branchID: ID!, $sourceService: OpeningTimeServiceType!, $targetService: OpeningTimeServiceType!) {\n    copyOpeningTimes(branchID: $branchID, sourceService: $sourceService,targetService: $targetService)\n  }\n"): (typeof documents)["\n  mutation CopyOpeningTime($branchID: ID!, $sourceService: OpeningTimeServiceType!, $targetService: OpeningTimeServiceType!) {\n    copyOpeningTimes(branchID: $branchID, sourceService: $sourceService,targetService: $targetService)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateManyOpeningTime($input: CreateManyOpeningTimeData!) {\n        createManyOpeningTime(input: $input) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation CreateManyOpeningTime($input: CreateManyOpeningTimeData!) {\n        createManyOpeningTime(input: $input) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOpeningTime($input: CreateOpeningTimeData!) {\n    createOpeningTime(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOpeningTime($input: CreateOpeningTimeData!) {\n    createOpeningTime(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteOpeningTime($id: ID!) {\n    deleteOpeningTime(id: $id) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation deleteOpeningTime($id: ID!) {\n    deleteOpeningTime(id: $id) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OpeningTime($id: ID!) {\n    openingTime(id: $id) {\n      id\n      branchId\n      dayOfWeek\n      openTime\n      closeTime\n      note\n      isActive\n    }\n  }\n"): (typeof documents)["\n  query OpeningTime($id: ID!) {\n    openingTime(id: $id) {\n      id\n      branchId\n      dayOfWeek\n      openTime\n      closeTime\n      note\n      isActive\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateOpeningTime($id: ID!, $input: UpdateOpeningTimeData!) {\n    updateOpeningTime(id: $id, input: $input) {\n        id\n        branchId\n        dayOfWeek\n        openTime\n        closeTime\n        note\n        isActive\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateOpeningTime($id: ID!, $input: UpdateOpeningTimeData!) {\n    updateOpeningTime(id: $id, input: $input) {\n        id\n        branchId\n        dayOfWeek\n        openTime\n        closeTime\n        note\n        isActive\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OpeningTimes($branchId: ID!, $filter: OpeningTimeFilter) {\n    openingTimes(branchId: $branchId, filter: $filter) {\n      ...OpeningTimeItem\n    }\n  }\n"): (typeof documents)["\n  query OpeningTimes($branchId: ID!, $filter: OpeningTimeFilter) {\n    openingTimes(branchId: $branchId, filter: $filter) {\n      ...OpeningTimeItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTableGroup(\n    $branchID: ID!\n    $name: String!\n    $arrangementCode: String\n  ) {\n    createTableGroup(\n      branchID: $branchID\n      input: { name: $name, arrangementCode: $arrangementCode }\n    ) {\n      id\n      name\n      arrangementCode\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTableGroup(\n    $branchID: ID!\n    $name: String!\n    $arrangementCode: String\n  ) {\n    createTableGroup(\n      branchID: $branchID\n      input: { name: $name, arrangementCode: $arrangementCode }\n    ) {\n      id\n      name\n      arrangementCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteTableGroup($id: ID!) {\n    deleteTableGroup(id: $id) {\n      id\n      name\n      arrangementCode\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteTableGroup($id: ID!) {\n    deleteTableGroup(id: $id) {\n      id\n      name\n      arrangementCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTableGroup(\n    $id: ID!\n    $name: String!\n    $arrangementCode: String\n  ) {\n    updateTableGroup(\n      id: $id\n      input: {\n        name: $name\n        arrangementCode: $arrangementCode\n        gridLayout: {\n          mesh: {\n            rows: 0\n            columns: 0\n            entries: { row: 0, column: 0, tableId: \"\" }\n          }\n        }\n      }\n    ) {\n      id\n      name\n      arrangementCode\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTableGroup(\n    $id: ID!\n    $name: String!\n    $arrangementCode: String\n  ) {\n    updateTableGroup(\n      id: $id\n      input: {\n        name: $name\n        arrangementCode: $arrangementCode\n        gridLayout: {\n          mesh: {\n            rows: 0\n            columns: 0\n            entries: { row: 0, column: 0, tableId: \"\" }\n          }\n        }\n      }\n    ) {\n      id\n      name\n      arrangementCode\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableGroupsList($branchID: ID!) {\n    tableGroups(branchID: $branchID) {\n      ...TableGroupItem\n    }\n  }\n"): (typeof documents)["\n  query TableGroupsList($branchID: ID!) {\n    tableGroups(branchID: $branchID) {\n      ...TableGroupItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableReservationReportByBranch($branchId: ID!) {\n    tableReservationReport(branchID: $branchId) {\n      daily {\n        date\n        count\n      }\n      hourly {\n        hour\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query TableReservationReportByBranch($branchId: ID!) {\n    tableReservationReport(branchID: $branchId) {\n      daily {\n        date\n        count\n      }\n      hourly {\n        hour\n        count\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableReservationConfirmationDecision($id: ID!, $branchID: ID!) {\n    tableReservation(id: $id) {\n      ...TableReservationItem\n    }\n    tablesByBranch(branchID: $branchID) {\n      ...TableWithGroupItem\n    }\n  }\n"): (typeof documents)["\n  query TableReservationConfirmationDecision($id: ID!, $branchID: ID!) {\n    tableReservation(id: $id) {\n      ...TableReservationItem\n    }\n    tablesByBranch(branchID: $branchID) {\n      ...TableWithGroupItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTableBooking($branchId: ID!, $input: TableReservationCreate!) {\n    createTableReservation(branchId: $branchId, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTableBooking($branchId: ID!, $input: TableReservationCreate!) {\n    createTableReservation(branchId: $branchId, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableReservationDetail($id: ID!) {\n    tableReservation(id: $id) {\n      ...TableReservationItem\n    }\n  }\n"): (typeof documents)["\n  query TableReservationDetail($id: ID!) {\n    tableReservation(id: $id) {\n      ...TableReservationItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation TableReservationUpdate($id: ID!, $input: TableReservationUpdate!) {\n    updateTableReservation(id: $id, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"): (typeof documents)["\n  mutation TableReservationUpdate($id: ID!, $input: TableReservationUpdate!) {\n    updateTableReservation(id: $id, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BranchTableReservationInfo($companyID: ID!) {\n    tableReservationBranches(companyID: $companyID) {\n      branchId\n      branchName\n      tables\n      bookingTable\n      tableReservationSettingId\n    }\n  }\n"): (typeof documents)["\n  query BranchTableReservationInfo($companyID: ID!) {\n    tableReservationBranches(companyID: $companyID) {\n      branchId\n      branchName\n      tables\n      bookingTable\n      tableReservationSettingId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription TableBookingUpdateSubscribe($branchId: ID!) {\n    tableReservationUpdateSubscribe(branchId: $branchId) {\n      event\n      tableReservation {\n        ...TableReservationItem\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription TableBookingUpdateSubscribe($branchId: ID!) {\n    tableReservationUpdateSubscribe(branchId: $branchId) {\n      event\n      tableReservation {\n        ...TableReservationItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableGroups($branchId: ID!) {\n    tableGroups(branchID: $branchId) {\n      id\n      name\n      arrangementCode\n      tables {\n        id\n        name\n        seats\n        tableGroupId\n      }\n    }\n  }\n"): (typeof documents)["\n  query TableGroups($branchId: ID!) {\n    tableGroups(branchID: $branchId) {\n      id\n      name\n      arrangementCode\n      tables {\n        id\n        name\n        seats\n        tableGroupId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableReservationOperative($branchId: ID!) {\n    tableReservationsOperative(branchId: $branchId) {\n      ...TableReservationItem\n    }\n    tableReservationConfiguration(branchID: $branchId) {\n      id\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n    }\n  }\n"): (typeof documents)["\n  query TableReservationOperative($branchId: ID!) {\n    tableReservationsOperative(branchId: $branchId) {\n      ...TableReservationItem\n    }\n    tableReservationConfiguration(branchID: $branchId) {\n      id\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TableReservations($branchId: ID!, $filter: TableReservationsFilter!) {\n    tableReservations(branchId: $branchId, filter: $filter) {\n      ...TableReservationItem\n    }\n  }\n"): (typeof documents)["\n  query TableReservations($branchId: ID!, $filter: TableReservationsFilter!) {\n    tableReservations(branchId: $branchId, filter: $filter) {\n      ...TableReservationItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Table($tableId: ID!) {\n    table(id: $tableId) {\n      id\n      name\n      tableGroupId\n    }\n  }\n"): (typeof documents)["\n  query Table($tableId: ID!) {\n    table(id: $tableId) {\n      id\n      name\n      tableGroupId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query TablesByBranch($branchId: ID!) {\n    tablesByBranch(branchID: $branchId) {\n      ...TableWithGroupItem\n    }\n  }\n"): (typeof documents)["\n  query TablesByBranch($branchId: ID!) {\n    tablesByBranch(branchID: $branchId) {\n      ...TableWithGroupItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateConfirmTableReservation(\n    $id: ID!\n    $status: TableReservationConfirmationStatus!\n  ) {\n    updateTableReservationConfirmationStatus(id: $id, status: $status) {\n      id\n      confirmationStatus\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateConfirmTableReservation(\n    $id: ID!\n    $status: TableReservationConfirmationStatus!\n  ) {\n    updateTableReservationConfirmationStatus(id: $id, status: $status) {\n      id\n      confirmationStatus\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateStatusOnTableBooking(\n    $id: ID!\n    $status: TableReservationStatus!\n  ) {\n    updateTableReservationStatus(id: $id, status: $status) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateStatusOnTableBooking(\n    $id: ID!\n    $status: TableReservationStatus!\n  ) {\n    updateTableReservationStatus(id: $id, status: $status) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTableOnTableBooking($id: ID!, $tableId: ID) {\n    updateTableReservationTable(id: $id, tableId: $tableId) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTableOnTableBooking($id: ID!, $tableId: ID) {\n    updateTableReservationTable(id: $id, tableId: $tableId) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteAllTableReservations($branchID: ID!) {\n    deleteAllTableReservations(branchId: $branchID)\n  }\n"): (typeof documents)["\n  mutation DeleteAllTableReservations($branchID: ID!) {\n    deleteAllTableReservations(branchId: $branchID)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query BranchTableBookingConfiguration($branchID: ID!) {\n    tableReservationConfiguration(branchID: $branchID) {\n      id\n      hourBlocking\n      seatLimit\n      flexDuration\n      defaultBookingTimeDuration\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n      notificationMails\n      phone\n      reservationLeadMinutes\n      reservationTraitMinutes\n      reminderTime\n      flexEnabled  \n    }\n  }\n"): (typeof documents)["\n  query BranchTableBookingConfiguration($branchID: ID!) {\n    tableReservationConfiguration(branchID: $branchID) {\n      id\n      hourBlocking\n      seatLimit\n      flexDuration\n      defaultBookingTimeDuration\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n      notificationMails\n      phone\n      reservationLeadMinutes\n      reservationTraitMinutes\n      reminderTime\n      flexEnabled  \n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateBranchTableBookingConfiguration(\n    $id: ID!\n    $input: TableReservationConfigurationUpdateInput!\n  ) {\n    updateTableReservationConfiguration(id: $id, input: $input) {\n      id\n      hourBlocking\n      defaultBookingTimeDuration\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n      notificationMails\n      phone\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateBranchTableBookingConfiguration(\n    $id: ID!\n    $input: TableReservationConfigurationUpdateInput!\n  ) {\n    updateTableReservationConfiguration(id: $id, input: $input) {\n      id\n      hourBlocking\n      defaultBookingTimeDuration\n      status\n      pause\n      autoConfirm\n      ignoreStatus\n      notificationMails\n      phone\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createTableBatch($tableGroup: ID!, $data: [CreateTable!]!) {\n    createTableBatch(tableGroup: $tableGroup, data: $data) {\n      count\n    }\n  }\n"): (typeof documents)["\n  mutation createTableBatch($tableGroup: ID!, $data: [CreateTable!]!) {\n    createTableBatch(tableGroup: $tableGroup, data: $data) {\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateTableBatch($data: [BatchUpdateTableEntry!]!) {\n    updateTableBatch(data: $data) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation updateTableBatch($data: [BatchUpdateTableEntry!]!) {\n    updateTableBatch(data: $data) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteTableBatch($ids: [ID!]!) {\n    deleteTableBatch(ids: $ids) {\n      count\n    }\n  }\n"): (typeof documents)["\n  mutation deleteTableBatch($ids: [ID!]!) {\n    deleteTableBatch(ids: $ids) {\n      count\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query tableGroup($id: ID!) {\n    tableGroup(id: $id) {\n      id\n      name\n      arrangementCode\n      tables {\n        ...TableItem\n      }\n    }\n  }\n"): (typeof documents)["\n  query tableGroup($id: ID!) {\n    tableGroup(id: $id) {\n      id\n      name\n      arrangementCode\n      tables {\n        ...TableItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createTeam($branchId: ID!, $input: CreateTeamInput!, $memberTeam: [TeamMemberCreateInput!]!) {\n    createTeam(branchId: $branchId, input: $input, memberTeam: $memberTeam) {\n      id\n      name\n      description\n    }\n  }\n"): (typeof documents)["\n  mutation createTeam($branchId: ID!, $input: CreateTeamInput!, $memberTeam: [TeamMemberCreateInput!]!) {\n    createTeam(branchId: $branchId, input: $input, memberTeam: $memberTeam) {\n      id\n      name\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateTeam($id: ID!, $input: UpdateTeamInput!, $memberTeam: [TeamMemberUpdateInput!]!) {\n    updateTeam(id: $id, input: $input, memberTeam: $memberTeam) {\n      id\n      name\n      description\n    }\n  }\n"): (typeof documents)["\n  mutation updateTeam($id: ID!, $input: UpdateTeamInput!, $memberTeam: [TeamMemberUpdateInput!]!) {\n    updateTeam(id: $id, input: $input, memberTeam: $memberTeam) {\n      id\n      name\n      description\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query userNotifications($companyID: ID) {\n    userNotifications(companyID: $companyID) {\n      id\n      title\n      link\n      message\n      createdAt\n    }\n  }\n"): (typeof documents)["\n  query userNotifications($companyID: ID) {\n    userNotifications(companyID: $companyID) {\n      id\n      title\n      link\n      message\n      createdAt\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation QuickCreateAccount($input: QuickSetupInput!) {\n        clientQuickSetup(input: $input) {\n            username\n            password\n        }\n    }\n"): (typeof documents)["\n    mutation QuickCreateAccount($input: QuickSetupInput!) {\n        clientQuickSetup(input: $input) {\n            username\n            password\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query DailyInvoiceReport($companyID: ID!) {\n    analytic__invoice_report(companyID: $companyID) {\n      daily {\n        date\n        total\n        count\n      }\n    }\n  }\n"): (typeof documents)["\n  query DailyInvoiceReport($companyID: ID!) {\n    analytic__invoice_report(companyID: $companyID) {\n      daily {\n        date\n        total\n        count\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Analytic__invoice_aggregated_report_by_date(\n    $companyID: ID!\n    $startDate: String!\n    $endDate: String!\n  ) {\n    analytic__invoice_aggregated_report_by_date(\n      companyID: $companyID\n      startDate: $startDate\n      endDate: $endDate\n    ) {\n      totalInvoices\n      totalAmount\n    }\n  }\n"): (typeof documents)["\n  query Analytic__invoice_aggregated_report_by_date(\n    $companyID: ID!\n    $startDate: String!\n    $endDate: String!\n  ) {\n    analytic__invoice_aggregated_report_by_date(\n      companyID: $companyID\n      startDate: $startDate\n      endDate: $endDate\n    ) {\n      totalInvoices\n      totalAmount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ResetCurrentUserPassword(\n    $userID: ID!\n    $input: ResetCurrentUserPasswordInput!\n  ) {\n    resetCurrentUserPassword(userID: $userID, input: $input)\n  }\n"): (typeof documents)["\n  mutation ResetCurrentUserPassword(\n    $userID: ID!\n    $input: ResetCurrentUserPasswordInput!\n  ) {\n    resetCurrentUserPassword(userID: $userID, input: $input)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UserCompanyRoleCreate(\n    $companyID: ID!\n    $input: UserCompanyRoleCreate!\n  ) {\n    userCompanyRoleCreate(companyID: $companyID, input: $input) {\n      password\n      userCompanyRole{\n        user {\n          id\n          firstName\n          lastName\n          username\n          email\n          status\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UserCompanyRoleCreate(\n    $companyID: ID!\n    $input: UserCompanyRoleCreate!\n  ) {\n    userCompanyRoleCreate(companyID: $companyID, input: $input) {\n      password\n      userCompanyRole{\n        user {\n          id\n          firstName\n          lastName\n          username\n          email\n          status\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UserCompanyRoleDelete($companyID: ID!, $userID: ID!) {\n    userCompanyRoleDelete(companyID: $companyID, userID: $userID) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UserCompanyRoleDelete($companyID: ID!, $userID: ID!) {\n    userCompanyRoleDelete(companyID: $companyID, userID: $userID) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ResetUserPassword($userID: ID!, $companyID: ID!){\n        resetUserPassword(userID: $userID, companyID: $companyID)\n    }\n"): (typeof documents)["\n    mutation ResetUserPassword($userID: ID!, $companyID: ID!){\n        resetUserPassword(userID: $userID, companyID: $companyID)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UserCompanyRoleUpdate(\n    $companyID: ID!\n    $input: UserCompanyRoleUpdate!\n  ) {\n    userCompanyRoleUpdate(companyID: $companyID, input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation UserCompanyRoleUpdate(\n    $companyID: ID!\n    $input: UserCompanyRoleUpdate!\n  ) {\n    userCompanyRoleUpdate(companyID: $companyID, input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query User($companyID: ID!, $userID: ID!) {\n    userCompanyRole(companyID: $companyID, userID: $userID){\n      permission\n    }\n\n    user(id: $userID) {\n      id\n      firstName\n      lastName\n      username\n      email\n      status\n    }\n  }\n"): (typeof documents)["\n  query User($companyID: ID!, $userID: ID!) {\n    userCompanyRole(companyID: $companyID, userID: $userID){\n      permission\n    }\n\n    user(id: $userID) {\n      id\n      firstName\n      lastName\n      username\n      email\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserCompanyRoles($companyID: ID!, $offset: Int!, $limit: Int!) {\n    userCompanyRoles(companyID: $companyID, offset: $offset, limit: $limit) {\n      user {\n        id\n        firstName\n        lastName\n        username\n        role\n        email\n        status\n      }\n      permission\n    }\n  }\n"): (typeof documents)["\n  query UserCompanyRoles($companyID: ID!, $offset: Int!, $limit: Int!) {\n    userCompanyRoles(companyID: $companyID, offset: $offset, limit: $limit) {\n      user {\n        id\n        firstName\n        lastName\n        username\n        role\n        email\n        status\n      }\n      permission\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserCompanyRolesCount($companyID: ID!) {\n    userCompanyRolesCount(companyID: $companyID)\n  }\n"): (typeof documents)["\n  query UserCompanyRolesCount($companyID: ID!) {\n    userCompanyRolesCount(companyID: $companyID)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CurrentUser{\n    currentUser{\n        id\n        email\n        firstName\n        lastName\n        username\n        status\n        role\n    }\n  }\n"): (typeof documents)["\n  query CurrentUser{\n    currentUser{\n        id\n        email\n        firstName\n        lastName\n        username\n        status\n        role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PickedNailAppointmentTeamItem on PickedNailAppointmentTeam {\n    id\n    teamName\n    appointmentId\n    teamId\n    team {\n      teamMember {\n        userId\n        user {\n          id\n          username\n          lastName\n          firstName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment PickedNailAppointmentTeamItem on PickedNailAppointmentTeam {\n    id\n    teamName\n    appointmentId\n    teamId\n    team {\n      teamMember {\n        userId\n        user {\n          id\n          username\n          lastName\n          firstName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PickedNailAppointmentEmployeeItem on PickedNailAppointmentEmployee {\n    id\n    appointmentId\n    userId\n    user {\n      id\n      username\n      firstName\n      lastName\n      userId\n    }\n  }\n"): (typeof documents)["\n  fragment PickedNailAppointmentEmployeeItem on PickedNailAppointmentEmployee {\n    id\n    appointmentId\n    userId\n    user {\n      id\n      username\n      firstName\n      lastName\n      userId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuItem on Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n  }\n"): (typeof documents)["\n  fragment MenuItem on Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n"): (typeof documents)["\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n"): (typeof documents)["\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductItem on MenuProduct {\n    id\n    code\n    title\n    priceMode\n    description\n    images\n    ingredients\n    labels\n    priceMode\n    allergics\n    enabled\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      id\n      title\n      type\n      values {\n        ...MenuProductConfigurationValueItem\n      }\n    }\n\n    menuId\n    sectionId\n  }\n"): (typeof documents)["\n  fragment MenuProductItem on MenuProduct {\n    id\n    code\n    title\n    priceMode\n    description\n    images\n    ingredients\n    labels\n    priceMode\n    allergics\n    enabled\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      id\n      title\n      type\n      values {\n        ...MenuProductConfigurationValueItem\n      }\n    }\n\n    menuId\n    sectionId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuSectionItem on MenuSection {\n    id\n    name\n    description\n    code\n  }\n"): (typeof documents)["\n  fragment MenuSectionItem on MenuSection {\n    id\n    name\n    description\n    code\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ServiceItem on NailAppointmentMenuProduct {\n    id\n    code\n    title\n    description\n    images\n    enabled\n    ingredients\n    labels\n    allergics\n    priceMode\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    appointmentMenuProductData {\n      duration\n    }\n\n    menuId\n    sectionId\n  }\n"): (typeof documents)["\n  fragment ServiceItem on NailAppointmentMenuProduct {\n    id\n    code\n    title\n    description\n    images\n    enabled\n    ingredients\n    labels\n    allergics\n    priceMode\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    appointmentMenuProductData {\n      duration\n    }\n\n    menuId\n    sectionId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment TeamItem on Team {\n    id\n    name\n    description\n    teamMember {\n      id\n      userId\n      user {\n        username\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment TeamItem on Team {\n    id\n    name\n    description\n    teamMember {\n      id\n      userId\n      user {\n        username\n        firstName\n        lastName\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;