import { useNavigate, useParams } from 'react-router-dom';
import Card from '@packages/ui/card/card.tsx';
import CardHeader from '@packages/ui/card/card-header.tsx';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import AlertError from '@packages/ui/alert/alert-error.tsx';
import MenuProductTable from '@client/page/management/menu/menu-detail/component/menu-product-table.tsx';
import Loading from '@packages/ui/loading.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import { useCallback, useEffect } from 'react';
import CardTableContent from '@packages/ui/card/card-content-table.tsx';
import { useTranslate } from '@tolgee/react';
import { Helmet } from 'react-helmet-async';
import EditMenuButton from '@client/page/management/menu/menu-detail/component/menu-edit-button.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { MenuProductPriceMode } from '@client/graphql/types/graphql.ts';
import { Controller, useForm } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/react';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import useUpdateMenu from '@client/page/management/menu/menu-edit/logic/use-update-menu.ts';
import useDeleteMenu from '@client/page/management/menu/menu-detail/logic/use-delete-menu.ts';
import { useDialog } from '@packages/ui/modal/use-dialog.tsx';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import EditMenuTitleModal from '@client/page/management/menu/menu-detail/component/menu-update-title.tsx';
import { useGetMenu } from './logic/use-get-menu';

interface FormValues {
  title: string;
  allergics: string;
  preferredProductPriceMode: MenuProductPriceMode;
}

function MenuDetail() {
  const menuId = useParams().menuId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { showAlert } = useNotificationCenter();
  const priceModeOption = Object.values(MenuProductPriceMode);

  const { data, loading, error, refetch } = useGetMenu(menuId);

  const form = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    reValidateMode: 'onBlur',
  });
  const { register, handleSubmit, getValues, clearErrors, control, setError, reset } = form;
  useEffect(() => {
    if (data?.menu) {
      reset({
        title: data.menu.title,
        allergics: data.menu.allergics,
        preferredProductPriceMode: data.menu.preferredProductPriceMode,
      });
    }
  }, [data, reset]);

  const [update] = useUpdateMenu();
  const onSubmit = async (updateData: FormValues) => {
    try {
      const result = await update({
        variables: {
          menuId: menuId,
          input: {
            title: updateData.title.trim(),
            allergics: updateData.allergics,
            preferredProductPriceMode: updateData.preferredProductPriceMode,
          },
        },
      });
      if (result.data) {
        await refetch();
        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('alert.message.update-success', 'Update menu successfully'),
        });
      } else {
        showAlert({
          status: 'error',
          title: t('alert.title.error'),
          message: t('alert.message.update-fail', 'Update menu failed'),
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        const applicationErrors = formatGraphQlError(err.graphQLErrors);
        const validationError = validationErrors(applicationErrors);

        for (const field in validationError) {
          setError(
            field as keyof FormValues,
            {
              type: 'server',
              message: t(validationError[field]),
            },
            { shouldFocus: true }
          );
        }
      }
      captureException(err);
    }
  };

  const showUpdateTitle = () => {
    dialog.showComponent((onClose) => {
      return <EditMenuTitleModal title={getValues('title')} onClose={onClose} refetch={refetch} />;
    });
  };

  const [deleteMenu] = useDeleteMenu();
  const dialog = useDialog();
  const onDestroy = useCallback(() => {
    dialog.destructAlert({
      title: t('menu.menu-detail.extra-action.delete.alert.title'),
      message: t('menu.menu-detail.extra-action.delete.alert.message'),
      textButton: t('menu.menu-detail.extra-action.delete.alert.text-button'),
      onConfirm: async () => {
        await deleteMenu({ variables: { id: menuId } });
        showAlert({
          status: 'success',
          title: t('menu.menu-detail.notification.success.title', 'Delete successful'),
          message: t('menu.menu-detail.notification.success.message', 'Menu has been deleted successfully'),
        });
        navigate('..');
      },
    });
  }, [deleteMenu, dialog, navigate, menuId, showAlert, t]);

  useEffect(() => {
    navigate(location.pathname, {
      replace: true,
      state: { breadcrumbName: data?.menu.title },
    });
  }, [navigate, data?.menu.title]);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <AlertError title={error.name} message={error.message} />;
  }

  if (!data) {
    return <Card>No data</Card>;
  }
  return (
    <ContentLayout>
      <Helmet>
        <title>{t('menu.menu-detail.helmet.title')}</title>
        <meta name="description" content="This page displays the details of a menu"></meta>
      </Helmet>
      <PrimaryContent>
        <div className="flex-col space-y-4">
          <Card>
            <CardHeader title={data.menu.title} withBackButton={true}>
              <SecondaryButton
                onClick={() => {
                  navigate(`sections/create`);
                }}
              >
                {t('menu.menu-detail.table-product.button.add-category')}
              </SecondaryButton>

              <PrimaryButton
                onClick={() => {
                  navigate(`products/create?preferredProductPriceMode=${getValues('preferredProductPriceMode')}`);
                }}
              >
                {t('menu.menu-detail.table-product.button.add-product')}
              </PrimaryButton>

              <EditMenuButton deleteMenu={onDestroy} editTitle={showUpdateTitle} />
            </CardHeader>

            <CardTableContent>
              <MenuProductTable products={data.menu.menuProducts} sections={data.menu.sections} />
            </CardTableContent>
          </Card>

          <Card>
            <CardHeader title={t('menu.menu-detail.detail.header', 'Menu Detail')} />
            <CardContent>
              <form
                className="space-y-6"
                onSubmit={() => {
                  handleSubmit(onSubmit);
                }}
              >
                <FormItem className="max-w-md" title={t('menu.menu-create.form-input.menu-allergics.title', 'Allergy')}>
                  <textarea
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1
                             ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
                    {...register('allergics')}
                    defaultValue={data.menu.allergics || ''}
                  />
                  {form.formState.errors.allergics && (
                    <p className="text-red-500 text-xs pt-1">{form.formState.errors.allergics.message}</p>
                  )}
                </FormItem>

                <div className="max-w-md mt-6">
                  <Controller
                    control={control}
                    name="preferredProductPriceMode"
                    render={({ field }) => (
                      <MenuSelectionInput
                        title={t('menu.menu-create.form-input.menu-price-mode.title', 'Prefer Price mode')}
                        data={priceModeOption}
                        value={field.value}
                        onChange={field.onChange}
                        build={(item) => ({
                          id: item,
                          name: t(
                            `menu.menu-create.form-input.menu-price-mode.${item.toLowerCase()}`,
                            item.toLowerCase()
                          ),
                        })}
                      />
                    )}
                  />
                </div>
              </form>
            </CardContent>
            <CardFooter>
              <PrimaryButton
                id="create-button"
                data-testid="create-button"
                className="w-32"
                onClick={() => {
                  clearErrors();
                  handleSubmit(onSubmit)().catch(captureException);
                }}
                loading={loading}
              >
                {t('common.button.update')}
              </PrimaryButton>
            </CardFooter>
          </Card>
        </div>
      </PrimaryContent>
    </ContentLayout>
  );
}

export default MenuDetail;
