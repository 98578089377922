import { graphql } from '../graphql/types';

export const PickedNailAppointmentTeamFragment = graphql(`
  fragment PickedNailAppointmentTeamItem on PickedNailAppointmentTeam {
    id
    teamName
    appointmentId
    teamId
    team {
      teamMember {
        userId
        user {
          id
          username
          lastName
          firstName
        }
      }
    }
  }
`);

export const PickedNailAppointmentEmployeeFragment = graphql(`
  fragment PickedNailAppointmentEmployeeItem on PickedNailAppointmentEmployee {
    id
    appointmentId
    userId
    user {
      id
      username
      firstName
      lastName
      userId
    }
  }
`);
