import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation assignEmployeeForAppointment($appointmentId: ID!, $userId: ID!) {
    assignEmployeeForAppointment(appointmentId: $appointmentId, userId: $userId) {
      id
    }
  }
`);

export default function useAssignEmployeeForAppointment() {
  return useMutation(MUTATION, {});
}
