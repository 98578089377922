import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation switchTeamForAppointment($appointmentId: ID!, $teamId: ID!) {
    switchTeamForAppointment(appointmentId: $appointmentId, teamId: $teamId) {
      id
      teamName
      appointmentId
      teamId
    }
  }
`);

export default function useSwitchTeamForAppointment() {
  return useMutation(MUTATION, {});
}
