import Table from '@packages/ui/table/table.tsx';
import TableHeaderColumn from '@packages/ui/table/table-header-column.tsx';
import TableRow from '@packages/ui/table/table-row.tsx';
import TableRowColumn from '@packages/ui/table/table-row-cell.tsx';
import { NailAppointmentConfirmationStatus, NailAppointmentItemFragment } from '@client/graphql/types/graphql.ts';
import { Fragment, ReactElement } from 'react';
import { groupBy } from 'graphql/jsutils/groupBy';
import moment from 'moment-timezone';
import { TableSection } from '@packages/ui/table/table-section.tsx';
import { CheckCircleIcon, QuestionMarkCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import { useTranslate } from '@tolgee/react';
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';
import { getFragmentData } from '@client/graphql/types';
import {
  PickedNailAppointmentEmployeeFragment,
  PickedNailAppointmentTeamFragment,
} from '@packages/network-graphql/common/appointment-picked-team.ts';
import _ from 'lodash';
import { AppointmentTableListConfirmationStatus } from '@client/page/management/appointment/list/component/appointment-table-list-confirmation-status.tsx';

export default function NailAppointmentTableList(props: {
  loading?: boolean;
  error?: string;
  items: NailAppointmentItemFragment[];
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: (id: string) => void;
}) {
  const { items, actions } = props;
  const { t } = useTranslate();
  const company = useFocusedCompany();

  if (props.loading == false) {
    if (!props.error && items.length === 0) {
      return <div className="px-6 py-4 border-t">{t('appointment.no-appointment')}</div>;
    }
  }

  // Group by date
  const timezone = company.settings.timezone;
  const dateGroups = groupBy(items, (item) => {
    return moment(item.startTime).tz(timezone).format('DD MMM');
  });

  const dates = Array.from(dateGroups, ([date, appoinments]) => {
    const sortedAppointments = Array.from(appoinments);
    sortedAppointments.sort((a, b) => {
      return moment(a.startTime).isAfter(moment(b.startTime)) ? 1 : -1;
    });

    return {
      date,
      appointments: sortedAppointments,
    };
  });

  // Sort by date
  dates.sort((a, b) => {
    return moment(a.appointments[0].startTime).isAfter(moment(b.appointments[0].startTime)) ? 1 : -1;
  });

  return (
    <>
      <Table
        loading={props.loading}
        data={dates}
        error={props.error}
        build={(item) => (
          <Fragment key={item.date}>
            <TableSection key={item.date} colSpan={6}>
              <div className="flex justify-start">
                <span className="font-bold text-xl text-indigo-600">{item.date}</span>
              </div>
            </TableSection>

            {item.appointments.map((appointment) => (
              <AppointmentRow
                key={appointment.id}
                item={appointment}
                actions={actions}
                onClick={() => props.onClick?.(appointment.id)}
              />
            ))}
          </Fragment>
        )}
        rounded={false}
      >
        <TableHeaderColumn>{t('common.table-header-column.status')}</TableHeaderColumn>
        <TableHeaderColumn>{t('common.table-header-column.time')}</TableHeaderColumn>
        <TableHeaderColumn>{'Guest'}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">{'Team'}</TableHeaderColumn>
        <TableHeaderColumn className="hidden sm:table-cell">{'Service'}</TableHeaderColumn>
      </Table>
    </>
  );
}

function AppointmentRow(props: {
  item: NailAppointmentItemFragment;
  actions?: (ctx: { id: string }) => ReactElement;
  onClick?: () => void;
}) {
  const { item, onClick } = props;
  const company = useFocusedCompany();
  const timezone = company.settings.timezone;

  const teamName = getFragmentData(PickedNailAppointmentTeamFragment, item.teams)[0]?.teamName;
  const abbreviatedTeamName = _.join(
    _.map(_.split(teamName, ' '), (word) => word[0]),
    ''
  ).toUpperCase();

  const employeeName = getFragmentData(PickedNailAppointmentEmployeeFragment, item.employees)[0]?.user.username ?? '';
  const serviceName = item.products[0].pickedProduct.title;
  return (
    <>
      <TableRow key={item.id} className="cursor-pointer" onClick={onClick}>
        <TableRowColumn>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="w-full h-full relative"
          >
            <AppointmentTableListConfirmationStatus appointmentId={item.id} status={item.confirmationStatus} />
          </div>
        </TableRowColumn>

        {/* Start time */}
        <TableRowColumn className="font-bold">
          {nailAppointmentTimeFormat(item.startTime ?? '', timezone)}
        </TableRowColumn>

        {/* Name */}
        <TableRowColumn>
          <span>{item.customerVariant.name}</span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        {/* Team */}
        <TableRowColumn className="hidden sm:table-cell	">
          {teamName ? (
            <div className="font-semibold flex items-center gap-1.5 truncate">
              <span className="p-1.5 rounded-full bg-gray-200">{abbreviatedTeamName}</span>
              {employeeName && <span>{employeeName}</span>}
            </div>
          ) : (
            <span className="italic text-gray-400">No assigned team</span>
          )}
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>

        {/* Service */}
        <TableRowColumn className="hidden sm:table-cell">
          <span className="font-semibold truncate max-w-32 ">{serviceName}</span>
          {/*<span className="md:hidden"> ({item.seats})</span>*/}
        </TableRowColumn>
      </TableRow>
    </>
  );
}

export function NailAppointmentIcon(props: { status: NailAppointmentConfirmationStatus }) {
  const { status } = props;

  switch (status) {
    case NailAppointmentConfirmationStatus.Confirmed:
      return <CheckCircleIcon className="w-6 h-6 fill-green-500" />;
    case NailAppointmentConfirmationStatus.Cancelled:
      return <XCircleIcon className="w-6 h-6 fill-red-500" />;
    case NailAppointmentConfirmationStatus.Waiting:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
    default:
      return <QuestionMarkCircleIcon className="w-6 h-6 fill-yellow-500" />;
  }
}

function nailAppointmentTimeFormat(dateString: string, timezone: string): string {
  const date = moment(dateString).tz(timezone);
  return date.format('HH:mm');
}
