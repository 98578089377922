import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import ProtectedRoutes from '@client/app/protected-routes.tsx';
import { ErrorBoundary } from '@client/app/component/global-error-boundary.tsx';
import App from '@client/app/app.tsx';
import Root from '@client/app/root.tsx';
import FocusedCompany from '@client/page/splash/focused-company.tsx';
import { TableReservationRouter } from '@client/page/management/table-reservation/table-reservation.router.tsx';
import { MenuRouter } from '@client/page/management/menu/menu.router.tsx';
import { BannerRouter } from '@client/page/management/banner/banner.router.tsx';
import { BranchRouter } from '@client/page/management/branch/branch.router.tsx';
import { TableRouter } from '@client/page/management/table/table.router.tsx';
import { UserCompanyRouter } from '@client/page/user-company-role/user-company.router.tsx';
import { OnlineOrderRouter } from '@client/page/management/online-order/online-order.router';
import { AuthProtection } from '@client/app/protections/auth-protection.tsx';
import FocusedBranch
  from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch.tsx';
import SignIn from '@client/page/auth/signin.tsx';
import CompanySelectionPage from '@client/page/company/company-selection-page.tsx';
import CompanyCreate from '@client/page/company/company-create.tsx';
import UserProfile from '@client/page/user-profile/user-profile';
import UserNotificationListPage
  from '@client/page/management/user-notificartion/user-notification-list/user-notification-list-page';
import ResetPassword from '@client/page/reset-password/reset-password';
import CompanySettings from '@client/page/management/company-settings/company-settings';
import { DashboardRouter } from '@client/page/dashboard/dashboard.router.tsx';
import { InvoiceRouter } from '@client/page/management/invoice/invoice.router.tsx';
import BranchEdit from '@client/page/management/branch/branch-edit/components/branch-edit.tsx';
import CreateForm from '@client/page/quick-create-form/form/create-form.tsx';
import UserAccount from '@client/page/quick-create-form/result/user-account.tsx';
import { ApiKeyRouter } from '@client/page/api-key/api-key.router.tsx';
import { OpeningTimeServiceType } from '@client/graphql/types/graphql';
import OpeningTimeSetupPage from '@client/page/management/opening-time/opening-time-setup/opening-time-setup-page';
import { AppointmentRouter } from '@client/page/management/appointment/appointment.router.tsx';
import { TeamRouter } from '@client/page/management/team/team.router.tsx';
import HubPageSetting from '@client/page/management/company-settings/hubpage-setting.tsx';

export function lazyDefaultBuilder(loader: () => Promise<{ readonly default: () => JSX.Element }>) {
  return async () => {
    return {
      Component: (await loader()).default,
    };
  };
}

export const router = createBrowserRouter(
  [
    {
      errorElement: <ErrorBoundary />,
      element: <Root />,
      children: [
        {
          path: 'create-form',
          children: [
            {
              path: '',
              element: <CreateForm />,
            },
            {
              path: 'account',
              element: <UserAccount />,
            },
          ],
        },
        {
          path: 'login',
          element: <SignIn />,
        },

        {
          element: (
            <AuthProtection>
              <FocusedCompany>
                <FocusedBranch>
                  <Outlet />
                </FocusedBranch>
              </FocusedCompany>
            </AuthProtection>
          ),
          children: [
            {
              path: 'company-selection',
              children: [
                {
                  path: '',
                  element: <CompanySelectionPage />,
                },
                {
                  path: 'create',
                  element: <CompanyCreate />,
                },
              ],
            },
            {
              element: <ProtectedRoutes />,
              children: [
                {
                  element: <App />,
                  children: [
                    {
                      index: true,
                      element: <Navigate to={'dashboard'} />,
                    },
                    {
                      path: 'current-branch/:branchId',
                      element: <BranchEdit focusBranch={true} />,
                    },

                    DashboardRouter,
                    BannerRouter,
                    BranchRouter,
                    TableReservationRouter,
                    MenuRouter,
                    TableRouter,
                    UserCompanyRouter,
                    OnlineOrderRouter,
                    InvoiceRouter,
                    ApiKeyRouter,
                    AppointmentRouter,
                    TeamRouter,
                    // User profile
                    {
                      path: 'user-profile',
                      handle: {
                        crumb: () => 'User Profile',
                      },
                      element: <UserProfile />,
                    },

                    // User notification
                    {
                      path: 'user-notification',
                      handle: {
                        crumb: () => 'User Notification',
                      },
                      element: <UserNotificationListPage />,
                    },

                    // Reset password
                    {
                      path: 'reset-password',
                      handle: {
                        crumb: () => 'Reset password',
                      },
                      children: [
                        {
                          path: ':userID',
                          element: <ResetPassword />,
                        },
                      ],
                    },

                    // Settings
                    {
                      path: 'settings',
                      handle: {
                        crumb: () => 'Company Settings',
                      },
                      children: [
                        {
                          index: true,
                          path: 'company',
                          handle: {
                            crumb: () => 'Company Settings',
                          },
                          element: <CompanySettings />,
                        },
                        {
                          path: 'opening-time',
                          element: (
                            <OpeningTimeSetupPage title="Opening Time" service={OpeningTimeServiceType.Location} />
                          ),
                        },

                        //hubpage
                        {
                          path: 'hubpage',
                          handle: {
                            crumb: () => 'Hubpage',
                          },
                          element: <HubPageSetting />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  {},
);
