import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from 'antd';
import SecondaryButton from '@packages/ui/button/secondary-button.tsx';
import { useTranslate } from "@tolgee/react";
import { useFocusedCompany } from '@packages/core/company/focused-company-context.ts';

export function AppointmentSimpleFilter(props: {
  startTime: Dayjs;
  endTime: Dayjs;
  onChange: (dates: [Dayjs, Dayjs]) => void;
}) {
  const { t } = useTranslate();
  const { settings } = useFocusedCompany();

  return (
    <>
      <SecondaryButton
        onClick={() => {
          const today = dayjs().tz(settings.timezone);
          props.onChange([today.startOf('day'), today.endOf('day')]);
        }}
      >
        {t('common.button.today')}
      </SecondaryButton>

      <DatePicker
        allowClear={false}
        value={props.startTime}
        onChange={(date) => {
          props.onChange([date.startOf('day'), date.endOf('day')]);
        }}
      />
    </>
  );
}
