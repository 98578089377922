import { useCompanyId } from '@packages/core/company/focused-company-context.ts';
import { useCompanySettings } from '@client/page/management/company-settings/logic/use-company-setting.ts';

export function useCompanyHubpageLink(): string {
  const companyId = useCompanyId();
  const { data } = useCompanySettings(companyId);
  const branchId = data?.company?.branch?.[0]?.id ?? '';
  const subDomain = data?.company?.hubPage?.subDomain ?? '';

  const subdomainMode: boolean = import.meta.env.VITE_HUB_SUBDOMAIN_MODE as boolean;
  const hubHost: string = import.meta.env.VITE_HUB_HOST as string;
  return subdomainMode ? `https://${subDomain}.${hubHost}/${branchId}` : `https://${hubHost}/${branchId}`;
}
