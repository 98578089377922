import useFormData from '@client/module/utils/use-form-data';
import Card from 'ui/src/card/card';
import CardContent from 'ui/src/card/card-content';
import CardFooter from 'ui/src/card/card-footer';
import CardHeader from 'ui/src/card/card-header';
import { ContentLayout, PrimaryContent } from 'ui/src/content-layout';
import FormItem from 'ui/src/form/form-item';
import FormLayout from 'ui/src/form/form-layout';
import ToggleInput from 'ui/src/form/input/toggle-input';
import PrimaryButton from 'ui/src/button/primary-button';
import { useNotificationCenter } from 'ui/src/notification/notification-center';
import {
  PickupDiscountType,
  OnlineOrderBranchSettingItemFragment,
  OpeningTimeServiceType,
} from '@client/graphql/types/graphql';
import ApplicationErrorView from '@client/module/error/application-error-view';
import { useModalManagerContext } from 'ui/src/modal/modal-manager-context';
import SecondaryButton from 'ui/src/button/secondary-button';
import { useCompanyCurrency } from '@packages/core/company/focused-company-context.ts';
import { formatRawCurrency, normalizeCurrency } from '@packages/core/utils/currency-formatter.ts';
import OpeningTimeSetup from '@client/page/management/opening-time/opening-time-setup/opening-time-setup.tsx';
import PickupDiscountInput from '@client/page/management/online-order/setting/component/pickup-discount-input.tsx';
import {
  PickupDiscountTypeToRaw,
  PickupDiscountValueToDisplay,
} from '@client/page/management/online-order/setting/component/delivery-discord-util.tsx';
import OpeningTimeEdit from '@client/page/management/opening-time/opening-time-edit/opening-time-edit';
import OpeningTimeCreate from '@client/page/management/opening-time/opening-time-create/opening-time-create';
import { useMenuList } from '@client/page/management/menu/menu-list/logic/use-menu-list.ts';
import AlertError from 'ui/src/alert/alert-error';
import { useCallback, useEffect, useState } from 'react';
import { BlockerFunction, useBlocker } from 'react-router-dom';
import DestroyButton from 'ui/src/button/destroy-button';
import { useDialog } from 'ui/src/modal/use-dialog';
import { useTranslate } from '@tolgee/react';
import useUpdateOnlineOrderConfiguration from '../logic/use-update-online-order-branch-setting';

interface OnlineOrderBranchSettingForm {
  pickupEnable: boolean;
  deliveryEnable: boolean;
  defaultPreparingTime: number;
  minimumOrderAmountForDelivery: string;
  deliveryRadiusLimit: number;
  menuID: string | null;
  pickupDiscountAmount: string;
  pickupDiscountType: PickupDiscountType;
  autoDeliveryConfirm: boolean;
  autoPickupConfirm: boolean;
}

export default function OnlineOrderBranchSettingEditPickup(props: {
  onlineOrderBranchSettingData: OnlineOrderBranchSettingItemFragment;
}) {
  // Params
  const { onlineOrderBranchSettingData } = props;

  // Hooks
  const currency = useCompanyCurrency();
  const dialogManager = useModalManagerContext();
  const dialog = useDialog();
  const { showAlert } = useNotificationCenter();
  const { t } = useTranslate();

  const [hasBeenChanged, setHasBeenChanged] = useState<boolean>(false);

  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) => {
      return hasBeenChanged && currentLocation.pathname !== nextLocation.pathname;
    },
    [hasBeenChanged],
  );

  const blocker = useBlocker(shouldBlock);
  const {  loading: menuLoading } = useMenuList();
  const {
    data: form,
    manualHandle,
    setData,
  } = useFormData<OnlineOrderBranchSettingForm>({
    menuID: onlineOrderBranchSettingData.menuID ?? null,
    pickupEnable: onlineOrderBranchSettingData.pickupEnable,
    autoPickupConfirm: onlineOrderBranchSettingData.autoPickupConfirm,
    autoDeliveryConfirm: onlineOrderBranchSettingData.autoDeliveryConfirm,
    deliveryEnable: onlineOrderBranchSettingData.deliveryEnable,
    defaultPreparingTime: onlineOrderBranchSettingData.defaultPreparingTime,
    deliveryRadiusLimit: onlineOrderBranchSettingData.deliveryRadiusLimit / 1000,
    minimumOrderAmountForDelivery: formatRawCurrency(
      onlineOrderBranchSettingData.minimumOrderAmountForDelivery,
      currency,
    ).value,
    pickupDiscountType: onlineOrderBranchSettingData.pickupDiscountType,
    pickupDiscountAmount: PickupDiscountValueToDisplay(
      onlineOrderBranchSettingData.pickupDiscountType,
      onlineOrderBranchSettingData.pickupDiscountAmount,
      currency,
    ).toString(),
  });
  const [update, { loading: updateLoading, error: updateError }] = useUpdateOnlineOrderConfiguration();

  const reset = useCallback(() => {
    setData({
      menuID: onlineOrderBranchSettingData.menuID ?? null,
      pickupEnable: onlineOrderBranchSettingData.pickupEnable,
      autoPickupConfirm: onlineOrderBranchSettingData.autoPickupConfirm,
      deliveryEnable: onlineOrderBranchSettingData.deliveryEnable,
      autoDeliveryConfirm: onlineOrderBranchSettingData.autoDeliveryConfirm,
      defaultPreparingTime: onlineOrderBranchSettingData.defaultPreparingTime,
      deliveryRadiusLimit: onlineOrderBranchSettingData.deliveryRadiusLimit / 1000,
      minimumOrderAmountForDelivery: formatRawCurrency(
        onlineOrderBranchSettingData.minimumOrderAmountForDelivery,
        currency,
      ).value,
      pickupDiscountType: onlineOrderBranchSettingData.pickupDiscountType,
      pickupDiscountAmount: PickupDiscountValueToDisplay(
        onlineOrderBranchSettingData.pickupDiscountType,
        onlineOrderBranchSettingData.pickupDiscountAmount,
        currency,
      ).toString(),
    });

    blocker.reset?.();
  }, [
    blocker,
    currency,
    onlineOrderBranchSettingData.defaultPreparingTime,
    onlineOrderBranchSettingData.deliveryEnable,
    onlineOrderBranchSettingData.autoDeliveryConfirm,
    onlineOrderBranchSettingData.deliveryRadiusLimit,
    onlineOrderBranchSettingData.menuID,
    onlineOrderBranchSettingData.minimumOrderAmountForDelivery,
    onlineOrderBranchSettingData.pickupDiscountAmount,
    onlineOrderBranchSettingData.pickupDiscountType,
    onlineOrderBranchSettingData.pickupEnable,
    onlineOrderBranchSettingData.autoPickupConfirm,
    setData,
  ]);
  //Detect change between form and data
  useEffect(() => {
    if (
      form.menuID != onlineOrderBranchSettingData.menuID ||
      form.pickupEnable !== onlineOrderBranchSettingData.pickupEnable ||
      form.autoPickupConfirm !== onlineOrderBranchSettingData.autoPickupConfirm ||
      form.deliveryEnable !== onlineOrderBranchSettingData.deliveryEnable ||
      form.autoDeliveryConfirm !== onlineOrderBranchSettingData.autoDeliveryConfirm ||
      form.defaultPreparingTime !== onlineOrderBranchSettingData.defaultPreparingTime ||
      form.deliveryRadiusLimit !== onlineOrderBranchSettingData.deliveryRadiusLimit / 1000 ||
      normalizeCurrency(form.minimumOrderAmountForDelivery, currency) !==
      onlineOrderBranchSettingData.minimumOrderAmountForDelivery ||
      form.pickupDiscountType !== onlineOrderBranchSettingData.pickupDiscountType ||
      PickupDiscountTypeToRaw(form.pickupDiscountType, form.pickupDiscountAmount, currency) !==
      onlineOrderBranchSettingData.pickupDiscountAmount
    ) {
      setHasBeenChanged(true);
    } else {
      setHasBeenChanged(false);
    }
  }, [currency, form, onlineOrderBranchSettingData]);
  // Data
  const loading = menuLoading || updateLoading;
  // Callbacks
  const handleSubmit = () => {
    dialog.confirmAlert({
      title: t('online-order.setting-page.edit.dialog.title'),
      message: t('online-order.setting-page.edit.dialog.message'),
      textButton: t('online-order.setting-page.edit.dialog.text-button'),
      onConfirm: async () => {
        await update({
          variables: {
            branchID: onlineOrderBranchSettingData.branchID,
            input: {
              menuID: form.menuID,
              pickupEnable: form.pickupEnable,
              autoPickupConfirm: form.autoPickupConfirm,
              deliveryEnable: form.deliveryEnable,
              autoDeliveryConfirm: form.autoDeliveryConfirm,
              defaultPreparingTime: form.defaultPreparingTime,
              deliveryRadiusLimit: form.deliveryRadiusLimit * 1000,
              minimumOrderAmountForDelivery: normalizeCurrency(form.minimumOrderAmountForDelivery, currency),
              pickupDiscountType: form.pickupDiscountType,
              pickupDiscountAmount: PickupDiscountTypeToRaw(
                form.pickupDiscountType,
                form.pickupDiscountAmount,
                currency,
              ),
            },
          },
        });

        blocker.reset?.();

        showAlert({
          status: 'success',
          title: t('alert.title.success'),
          message: t('online-order.setting-page.edit.alert.success.message'),
        });
      },
    });
  };

  useEffect(() => {
    const handleBlocker = () => {
      dialogManager.showDialog((onClose) => (
        <Card>
          <CardHeader title={t('online-order.setting-page.edit.alert-changed.title')} />

          <CardContent>
            <p className="text-lg text-gray-600">{t('online-order.setting-page.edit.alert-changed.content')}</p>
          </CardContent>

          <CardFooter>
            <div className="w-full flex justify-between">
              <PrimaryButton
                disabled={loading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t('common.button.update')}
              </PrimaryButton>
              <div className="flex gap-2">
                <DestroyButton
                  onClick={() => {
                    reset();
                    onClose();
                  }}
                >
                  {t('common.button.discard')}
                </DestroyButton>

                <SecondaryButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('common.button.cancel')}
                </SecondaryButton>
              </div>
            </div>
          </CardFooter>
        </Card>
      ));
    };

    if (blocker.state == 'blocked') {
      handleBlocker();
    }

    return () => {
      blocker.reset?.();
    };
  });


  const showCreateModalOpeningTIme = (service: OpeningTimeServiceType) => {
    dialogManager.showDialog((onClose) => <OpeningTimeCreate onClose={onClose} service={service} />);
  };

  const showUpdateModalOpeningTime = (timeId: string) => {
    dialogManager.showDialog((onClose) => <OpeningTimeEdit onClose={onClose} openingTimeId={timeId} />);
  };

  return (
    <>
      <ContentLayout>
        <PrimaryContent>
          <ApplicationErrorView error={updateError} />
          {onlineOrderBranchSettingData.deliveryTableData?.length == 0 && (
            <AlertError
              title={t('online-order.setting-page.edit.alert-error.title')}
              message={t('online-order.setting-page.edit.alert-error.message')}
            />
          )}
          <Card>
            <CardHeader title={t('online-order.setting-page.edit.pickup.title','Pick up')} />

            <CardContent>
              <FormLayout>
                <FormItem title={t('online-order.setting-page.edit.general.pickup-toggle.title')}>
                  <p className="text-sm text-gray-500 italic my-4">
                    {t('online-order.setting-page.edit.general.pickup-toggle.description')}</p>
                  <ToggleInput
                    value={form.pickupEnable}
                    onChange={(newVal) => {
                      manualHandle('pickupEnable', newVal);
                    }}
                  />
                </FormItem>

                <FormItem
                  title={t('online-order.setting-page.edit.general.auto-pickup-toggle.title', 'Auto confirm pickup')}>
                  <p
                    className="text-sm text-gray-500 italic my-4">{t('online-order.setting-page.edit.general.auto-pickup-toggle.description',
                    'When you enable this toggle, your PICKUP orders will auto confirm and turn to state "In Progress"')}</p>
                  <ToggleInput
                    value={form.autoPickupConfirm}
                    onChange={(newVal) => {
                      manualHandle('autoPickupConfirm', newVal);
                    }}
                  />
                </FormItem>

                <PickupDiscountInput
                  currency={currency}
                  pickupDiscountAmount={form.pickupDiscountAmount}
                  pickupDiscountType={form.pickupDiscountType}
                  onTypeChange={(type) => {
                    manualHandle('pickupDiscountType', type);
                  }}
                  onAmountChange={(amount) => {
                    manualHandle('pickupDiscountAmount', amount);
                  }}
                />
              </FormLayout>
            </CardContent>

            <CardFooter>
              <PrimaryButton disabled={loading} onClick={handleSubmit}>
                {t('common.button.update')}
              </PrimaryButton>
            </CardFooter>
          </Card>


          <OpeningTimeSetup
            title={t('online-order.setting-page.edit.opening-time-setting.pick-up.title')}
            service={OpeningTimeServiceType.OnlineOrderPickup}
            showUpdateModalOpeningTime={showUpdateModalOpeningTime}
            showCreateModalOpeningTime={showCreateModalOpeningTIme}
          />


        </PrimaryContent>
      </ContentLayout>
    </>
  )
    ;
}
