import { ContentLayout, PrimaryContent } from '@packages/ui/content-layout.tsx';
import Card from '@packages/ui/card/card.tsx';
import { MenuSource, NailAppointmentConfigurationItemFragment } from '@client/graphql/types/graphql.ts';
import { Controller, useForm } from 'react-hook-form';
import CardHeader from '@packages/ui/card/card-header.tsx';
import CardContent from '@packages/ui/card/card-content.tsx';
import FormLayout from '@packages/ui/form/form-layout.tsx';
import FormItem from '@packages/ui/form/form-item.tsx';
import { useMenuList } from '@client/page/management/menu/menu-list/logic/use-menu-list.ts';
import MenuSelectionInput from '@packages/ui/form/input/menu-selection-input.tsx';
import { useTranslate } from '@tolgee/react';
import ToggleInput from '@packages/ui/form/input/toggle-input.tsx';
import CardFooter from '@packages/ui/card/card-footer.tsx';
import PrimaryButton from '@packages/ui/button/primary-button.tsx';
import Spinner from '@packages/ui/spinner.tsx';
import useUpdateAppointmentConfiguration from '@client/page/management/appointment/configuration/logic/use-update-appointment-configuration.ts';
import { useFocusedBranchContext } from '@client/page/management/table-group/table-group-list/component/branch-selection/focused-branch-context.ts';
import { useNotificationCenter } from '@packages/ui/notification/notification-center.ts';
import { ApolloError } from '@apollo/client';
import { formatGraphQlError, validationErrors } from '@client/module/error/error.ts';
import { captureException } from '@sentry/react';
import AppointmentStorefrontSetting from '@client/page/management/appointment/configuration/component/appointment-storefront-setting.tsx';
import TextInput from '@packages/ui/form/input/text-input.tsx';

interface AppointmentBranchConfiguration {
  menuId: string;
  enable: boolean;
  autoConfirm: boolean;
  hourBlocking: number;
  appointmentLeadMinutes: number;
  appointmentTraitMinutes: number;
  reminderTime: number;
}

export default function AppointmentBranchConfigurationGeneral(props: {
  configuration: NailAppointmentConfigurationItemFragment;
}) {
  const { t } = useTranslate();
  const { configuration } = props;
  const branch = useFocusedBranchContext();

  const { data: menuList, loading: menuLoading } = useMenuList(MenuSource.NailAppointment);
  const [update] = useUpdateAppointmentConfiguration();
  const { showAlert } = useNotificationCenter();
  const { control, setValue, setError, getValues } = useForm<AppointmentBranchConfiguration>({
    defaultValues: {
      menuId: configuration.menuId ?? '',
      enable: configuration.enable,
      autoConfirm: configuration.autoConfirm,
      hourBlocking: configuration.hourBlocking,
      appointmentLeadMinutes: configuration.appointmentLeadMinutes,
      appointmentTraitMinutes: configuration.appointmentTraitMinutes,
      reminderTime: configuration.reminderTime,
    },
  });

  if (menuLoading) {
    return <Spinner />;
  }

  const onSubmit = () => {
    update({
      variables: {
        branchId: branch.branch?.id ?? '',
        input: {
          enable: getValues('enable'),
          menuId: getValues('menuId'),
          autoConfirm: getValues('autoConfirm'),
          hourBlocking: Number(getValues('hourBlocking')),
          appointmentLeadMinutes: Number(getValues('appointmentLeadMinutes')),
          appointmentTraitMinutes: Number(getValues('appointmentTraitMinutes')),
          reminderTime: Number(getValues('reminderTime')),
        },
      },
    })
      .then(() => {
        showAlert({
          title: t('alert.title.success'),
          message: 'Your appoitment configuration is updated successfully.',
          status: 'success',
        });
      })
      .catch((err: Error) => {
        if (err instanceof ApolloError) {
          const applicationErrors = formatGraphQlError(err.graphQLErrors);
          const validationError = validationErrors(applicationErrors);
          for (const field in validationError) {
            setError(
              field as keyof AppointmentBranchConfiguration,
              {
                type: 'server',
                message: t(validationError[field]),
              },
              { shouldFocus: true }
            );
          }
        }

        captureException(err);
      });
  };

  return (
    <ContentLayout>
      <PrimaryContent>
        <Card>
          <CardHeader title={'Appointment configuration'} />
          <CardContent>
            <FormLayout>
              <FormItem className="max-w-md pb-4 border-b" title="">
                <Controller
                  control={control}
                  name="menuId"
                  render={({ field: { value } }) => (
                    <MenuSelectionInput
                      title={t('appointment.setting-page.edit.general.menu-selection.title', 'Menu')}
                      data={menuList?.menus ?? []}
                      value={menuList?.menus.find((item) => item.id == value) ?? null}
                      onChange={(newValue) => {
                        setValue('menuId', newValue.id);
                      }}
                      build={(item) => {
                        return {
                          id: item.id,
                          name: item.title,
                        };
                      }}
                      className="max-w-sm mb-2"
                    />
                  )}
                />
              </FormItem>

              <FormItem className="max-w-md pb-4 border-b" title="Appointment activate">
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.setting.form-input.active.description',
                    'If you enable this option, customers could make an appointment'
                  )}
                </p>
                <Controller
                  control={control}
                  name="enable"
                  render={({ field: { value, onChange } }) => <ToggleInput value={value} onChange={onChange} />}
                />
              </FormItem>

              <FormItem className="max-w-md pb-4 border-b" title="Hour blocking">
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.setting.form-input.hour-blocking.description',
                    'How many appointment options are there in 1 hour'
                  )}
                </p>
                <Controller
                  control={control}
                  name="hourBlocking"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="hour-blocking"
                      name="hourBlocking"
                      placeholder="Hour blocking"
                      type="number"
                    />
                  )}
                />
              </FormItem>

              <FormItem className="max-w-md pb-4 border-b" title="Auto confirm">
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.setting.form-input.auto-confirm.description',
                    'If you enable this option, appointment request is auto confirmed.'
                  )}
                </p>
                <Controller
                  control={control}
                  name="autoConfirm"
                  render={({ field: { value, onChange } }) => <ToggleInput value={value} onChange={onChange} />}
                />
              </FormItem>

              <FormItem className="max-w-md pb-4 border-b" title="Lead minutes">
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.setting.form-input.lead-minutes.description',
                    'Minimum time a customer can begin booking appointments.'
                  )}
                </p>

                <Controller
                  control={control}
                  name="appointmentLeadMinutes"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="appointment-lead-minutes"
                      name="appointmentLeadMinutes"
                      placeholder="Lead minutes"
                      type="number"
                    />
                  )}
                />
              </FormItem>

              <FormItem className="max-w-md pb-4 border-b" title="Trait minutes">
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.setting.form-input.trait-minutes.description',
                    'The last appointment could be allowed before closing (minutes).'
                  )}
                </p>

                <Controller
                  control={control}
                  name="appointmentTraitMinutes"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="appointment-trait-minutes"
                      name="appointmentTraitMinutes"
                      placeholder="Trait minutes"
                      type="number"
                    />
                  )}
                />
              </FormItem>

              <FormItem className="max-w-md pb-4" title="Reminder time">
                <p className="text-sm text-gray-500 italic my-4">
                  {t(
                    'appointment.setting.form-input.reminder-time.description',
                    'The system will send an email reminder to the customer before the appointment (Minutes).'
                  )}
                </p>
                <Controller
                  control={control}
                  name="reminderTime"
                  render={({ field: { value, onChange } }) => (
                    <TextInput
                      value={value}
                      onChange={onChange}
                      label="reminder-time"
                      name="reminderTime"
                      placeholder="Reminder time"
                      type="number"
                    />
                  )}
                />
              </FormItem>
            </FormLayout>
          </CardContent>

          <CardFooter>
            <PrimaryButton
              onClick={() => {
                onSubmit();
              }}
            >
              Update
            </PrimaryButton>
          </CardFooter>
        </Card>

        <AppointmentStorefrontSetting branchID={branch.branch?.id ?? ''} />
      </PrimaryContent>
    </ContentLayout>
  );
}
