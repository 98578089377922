import { graphql } from '@client/graphql/types';
import { useMutation } from '@apollo/client';

const MUTATION = graphql(`
  mutation UpdateAppointmentBranchConfiguration($branchId: ID!, $input: AppointmentConfigurationUpdateInput!) {
    updateAppointmentBranchConfiguration(branchId: $branchId, input: $input) {
      branchId
      enable
    }
  }
`);

export default function useUpdateAppointmentConfiguration() {
  return useMutation(MUTATION, {});
}
